<template>
	<div>
		<NewDatabaseDialog :dialog="dialogIncluirDatabase" @dialogClose="dialogIncluirDatabaseClose" :rtg_id="rtg_id" />

		<div>
			<v-btn color="primary" class="text-none mt-1" size="small" @click="incluirDatabaseClick"
				:disabled="!user.usuTerminalDbIncluir">
				<v-icon left>mdi-plus</v-icon>Adicionar Banco de Dados
			</v-btn>

			<v-data-table :headers="headers" :items="listDatabase" disable-pagination hide-default-footer height="400">
				<template v-slot:[`item.actions`]="{ item }">
					<span>
						<v-btn class="text-none" size="small" text @click="removerClick(item)"
							:disabled="!user.usuTerminalDbExcluir">
							<v-icon left>delete</v-icon>Excluir
						</v-btn>
					</span>
				</template>
			</v-data-table>
		</div>
	</div>
</template>
<script>
import NewDatabaseDialog from '../NewDatabaseDialog.vue';
import * as riddbService from '../../../services/riddbService.js'
import { mapGetters } from 'vuex'

export default {
	props: {
		listDatabase: Array,
		rtg_id: String,
	},
	components: {
		NewDatabaseDialog
	}, data: () => ({
		headers: [
			{ text: 'Id do Banco de Dados', value: 'rti_id' },
			{ text: 'Descrição', value: 'rti_descricao' },
			{ text: 'Ações', value: 'actions', align: 'right' },
		],
		dialogIncluirDatabase: false,
	}), methods: {
		incluirDatabaseClick() {
			this.dialogIncluirDatabase = true;
		}, dialogIncluirDatabaseClose() {
			this.dialogIncluirDatabase = false;
			this.$emit('databaseChanged')
		}, async removerClick(item) {
			// eslint-disable-next-line no-console
			//console.log(item);
			try {
				await riddbService.remove(item.rti_id);
				this.$emit('databaseChanged')
				this.$store.dispatch('showSuccess', 'Excluído com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		}
	}, watch: {
		dialog() {
		}
	}, computed: {
		...mapGetters(['user']),
	}
}
</script>