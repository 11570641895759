<template>
	<v-dialog v-model="relatorioDialog" max-width="340" scrollable>
		<v-card>
			<v-card-text class="pa-5">
				<b>Você realmente deseja excluir esse relatório?</b>
				<v-card-actions class="pt-3">
					<v-spacer />
					<v-btn color="error" @click="deletar">
						<v-icon left> mdi-delete </v-icon>
						Sim
					</v-btn>
					<v-btn @click="dialogClose">
						<v-icon left> mdi-close </v-icon>
						Não
					</v-btn>
				</v-card-actions>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	props: {
		dialog: Boolean,
	},
	data: function () {
		return {
			relatorio: {},
			loading: false,
			arquivo: null,
		}
	},
	computed: {
		relatorioDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
		deletar() {
			this.$emit('dialogClose')
			this.$emit('deletar')
		},
	},
}
</script>
