<template>
	<div>
		<v-dialog v-model="notificationDialog" max-width="1024" scrollable>
			<v-card>
				<v-card-title>
					<v-icon left>message</v-icon>Cadastro de Notificação
					<v-spacer></v-spacer>
					<v-btn icon @click="notificationDialog = false">
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="9">
							<v-text-field
								v-model="notification.msg"
								label="Menssagem"
								prepend-icon="notification_important"
								type="text"
								required
								:disabled="loading"
							/>
						</v-col>
						<v-col cols="12" sm="6" md="3">
							<v-menu v-model="menuDataExp" persistent width="290px">
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="dataExp"
										label="Data Expiração"
										persistent-hint
										prepend-icon="event"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker v-model="date" @input="menuDataExp = false"></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
					<v-spacer v-if="!loading"></v-spacer>
					<v-btn v-if="!loading" @click="notificationDialog = false">Sair</v-btn>
					<v-btn color="primary" @click="addNotification">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-card>
			<v-card-title>
				<v-spacer></v-spacer>
				<v-btn icon color="primary" x-large @click="newNotification" v-if="userAdmin">
					<v-icon>add_circle</v-icon>
				</v-btn>
			</v-card-title>
			<v-data-table :headers="headers" :items="notificacoes" disable-pagination hide-default-footer>
				<template v-slot:[`item.createdAt`]="{ item }">{{ convertDate(item.createdAt) }}</template>
				<template v-slot:[`item.dataExp`]="{ item }">{{ convertDate(item.dataExp) }}</template>
				<template v-if="userAdmin" v-slot:[`item.acao`]="{ item }">
					<v-btn title="Remover" icon @click="removeNotification(item.id)">
						<v-icon color="red">delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		clienteId: String,
		notificacoes: Array
	},
	data: () => ({
		headers: [
			{ text: 'Usuário', value: 'usuario' },
			{ text: 'Mensagem', value: 'msg' },
			{ text: 'Data Criação', value: 'createdAt' },
			{ text: 'Data Validade', value: 'dataExp' },
			{ text: 'Ações', value: 'acao', align: 'right' }
		],
		loading: false,
		notification: {},
		notificationDialog: false,
		date: null,
		menuDataExp: null
	}),
	computed: {
		...mapGetters(['userAdmin']),
		dataExp() {
			return this.convertDate(this.date)
		}
	},
	methods: {
		newNotification() {
			this.notificationDialog = true
			this.notification = {}
		},
		async addNotification() {
			try {
				this.loading = true
				await this.axios.post(`/clientes/${this.clienteId}/notificacoes`, this.notification)
				this.$store.dispatch('showSuccess', 'Notificação adicionada com sucesso!')
				this.notificationDialog = false
				this.$emit('notificationChanged')
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async removeNotification(id) {
			try {
				this.loading = true
				await this.axios.delete(`/notificacoes/${id}`)
				this.$store.dispatch('showSuccess', 'Notificação removida com sucesso!')
				this.$emit('notificationChanged')
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		}
	},
	watch: {
		date() {
			this.notification.dataExp = this.date
		}
	}
}
</script>