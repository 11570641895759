<template>
	<v-dialog v-model="isDialog" :max-width="600">
		<v-card>
			<v-card-title class="mb-2">
				Conexão Permitida
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text>
				<v-text-field label="IP Público" variant="outlined" v-model="model.rtc_ip"></v-text-field>

				<v-text-field label="Observação" variant="outlined" v-model="model.rtc_observacao"></v-text-field>

				<div class="d-flex justify-end">
					<v-btn color="success" class="text-none" size="small" @click="saveClick">
						Salvar
					</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>

import * as rtconexoesService from '../../services/rtconexoesService.js'

export default {
	props: {
		rtg_id: String,
		dialog: Boolean,
	},
	data: () => ({
		editando: false,
		model: {
			rtg_id: "",
			rtc_ip: "",
			rtc_observacao: ""
		}
	}),
	methods: {
		async saveClick() {
			if (this.validaSalvar() == false)
				return;

			try {
				this.$isLoading(true);
				this.model.rtg_id = this.rtg_id;

				await rtconexoesService.create(this.model);

				this.$store.dispatch('showSuccess', 'Token registrado com sucesso!')
				this.dialogClose();
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.$isLoading(false);
			}
		}, validaSalvar() {
			if (!this.model.rtc_ip || this.model.rtc_ip.length == 0) {
				this.$store.dispatch('showError', 'Informe o IP Publico')
				return false
			}
			if (!this.model.rtc_observacao || this.model.rtc_observacao.length == 0) {
				this.$store.dispatch('showError', 'Adicione uma Observação sobre o IP que está sendo adicionado')
				return false
			}
			return true
		}, dialogClose() {
			this.$emit('dialogClose')
		}, copiaChave(token) {
			navigator.clipboard.writeText(token)
			this.$store.dispatch('showSuccess', 'Token copiado com sucesso!')
		}
	}, computed: {
		isDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose();
			}
		}
	}, watch: {
		dialog() {
			this.model = {};
		}
	}
}
</script>