<template>
	<v-card>
		<v-card-title> <v-icon color="primary" left>mdi-tune</v-icon>Consulta PxAcertos </v-card-title>
		<v-card-text>
			<v-row>
				<v-col class="pl-7">
					<v-row>
						<v-radio-group v-model="tipoPx" row v-on:change="clientes = []" hide-details="">
							<v-radio label="Visualizar clientes com px" value="todosComPx" @click="loadPx"></v-radio>
						</v-radio-group>
					</v-row>
					<v-row>
						<v-radio-group v-model="tipoPx" row v-on:change="clientes = []">
							<v-radio label="Frente de Loja" value="px_paf"></v-radio>
							<v-radio label="Retaguarda" value="px_ret"></v-radio>
							<v-radio label="Loja" value="loja"></v-radio>
							<v-radio label="Usuário" value="usuario"></v-radio>
						</v-radio-group>
					</v-row>
				</v-col>
				<v-col class="d-flex align-end">
					<v-row>
						<v-col>
							Posição
							<v-text-field
								:disabled="tipoPx == 'todosComPx'"
								label="Posição"
								v-model="posicao"
								solo
								hide-details
								@keypress.enter="loadPx"
							></v-text-field>
						</v-col>
						<v-col>
							Conteúdo
							<v-text-field
								:disabled="tipoPx == 'todosComPx'"
								label="Conteúdo"
								v-model="conteudo"
								solo
								hide-details
								@keypress.enter="loadPx"
							></v-text-field>
						</v-col>
						<v-col>
							<v-btn
								color="primary"
								large
								@click="loadPx"
								width="100%"
								class="mt-6"
								:disabled="tipoPx == 'todosComPx'"
							>
								<v-icon>mdi-magnify</v-icon>Pesquisar</v-btn
							>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-text-field
				v-model="search"
				class="pb-2"
				solo
				placeholder="Procure uma empresa, usuário ou loja"
				hide-details
			></v-text-field>
			<v-data-table
				:headers="headers"
				:items="clientes"
				:server-items-length="total"
				:options.sync="options"
				class="elevation-1"
				:loading="loading"
			>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	data: function () {
		return {
			clientes: [],
			search: '',
			options: {},
			loading: false,
			total: 0,
			tipoPx: 'px_paf',
			px: '',
			posicao: '',
			conteudo: '',
		}
	},
	computed: {
		headers: function () {
			if (this.tipoPx == 'loja')
				return [
					{ text: 'Nome', value: 'nome' },
					{ text: 'CNPJ', value: 'cnpj' },
					{ text: 'Loja', value: 'loja' },
					{ text: 'Px', value: 'px' },
				]
			else if (this.tipoPx == 'usuario')
				return [
					{ text: 'Nome', value: 'nome' },
					{ text: 'CNPJ', value: 'cnpj' },
					{ text: 'Loja', value: 'loja' },
					{ text: 'Usuário', value: 'nomeUsuario' },
					{ text: 'Px', value: 'px' },
				]
			else if (this.tipoPx == 'todosComPx') {
				return [
					{ text: 'Nome', value: 'nome' },
					{ text: 'Data', value: 'data' },
				]
			} else
				return [
					{ text: 'Nome', value: 'nome' },
					{ text: 'CNPJ', value: 'cnpj' },
					{ text: 'Px', value: 'px' },
				]
		},
	},
	methods: {
		async loadPx() {
			try {
				this.loading = true

				if (!this.posicao && !this.tipoPx == 'todosComPx') {
					this.$store.dispatch('showError', 'Posição não informada')
					return
				}

				const { page, itemsPerPage } = this.options

				const response = await this.axios.get(`/pxAcertos`, {
					params: {
						page,
						limit: itemsPerPage,
						posicao: this.posicao,
						tipoPx: this.tipoPx,
						search: this.search,
						conteudo: this.conteudo,
					},
				})

				this.total = response.data.total
				this.clientes = response.data.clientes
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		search() {
			this.loadPx()
		},
		options: {
			handler() {
				if(this.tipoPx == 'todosComPx' || this.posicao != '')
				this.loadPx()
			},
			deep: true,
		},
	},
}
</script>
