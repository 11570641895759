<template>
	<v-dialog v-model="clienteDialog" :max-width="700" scrollable>
		<v-card>
			<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			<v-card-title class="mb-2">
				<v-icon left>mdi-cart</v-icon>
				Plug4Market
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-text-field label="Token de Acesso" v-model="cliente.marketplaceAccess"> </v-text-field>
				<v-text-field label="Token de Renovação" v-model="cliente.marketplaceRefresh"></v-text-field>
			</v-card-text>
			<v-row class="ma-0 pr-5 pb-5"> <v-spacer /> <v-btn color="primary" @click="salvar">Salvar</v-btn> </v-row>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
	props: {
		cliente: Object,
		dialog: Boolean,
	},
	data: function () {
		return {
			loading: false,
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
		async salvar() {
			try {
				this.loading = true
				const response = await this.axios.put(`/clientes/${this.cliente.id}/marketplace`, {
					accessToken: this.cliente.marketplaceAccess,
					refreshToken: this.cliente.marketplaceRefresh,
					accessDate: this.cliente.marketplaceAccessDate
				})

				if (response.status == 204) {
					this.$store.dispatch('showSuccess', 'Tokens alterados com sucesso com sucesso!')
					this.dialogClose()
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>