<template>
	<v-dialog v-model="clienteDialog" :max-width="400" scrollable>
		<v-card>
			<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			<v-card-title class="pb-0">
				<IconBase width="30" height="30" iconColor="#737373" class="mr-2"><IconMercadopago /></IconBase>
				Mercado Pago
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-col>
					<v-row>
						<v-col class="pl-0">
							<p class="mb-0 label">Ambiente:</p>
							<v-radio-group v-model="mercadopagoTeste" row class="mt-0" hide-details>
								<v-radio label="Produção" :value="false" />
								<v-radio label="Teste" :value="true" />
							</v-radio-group>
						</v-col>
					</v-row>

					<v-row>
						<v-text-field v-model="linkMercadopago" label="Link de autorização" readonly>
							<template v-slot:append>
								<v-icon color="primary" @click="copiarLink(linkMercadopago)" :disabled="loading">
									mdi-content-copy
								</v-icon>
							</template>
						</v-text-field>
					</v-row>
					<v-row>
						<v-text-field
							:disabled="loading"
							v-model="mercadopagoCode"
							label="Código de autorização"
						/>
					</v-row>
					<v-row>
						<v-spacer />
						<v-btn
							v-if="mercadopagoRefreshToken"
							color="error"
							@click="desativarMercadopago()"
							:disabled="loading"
							class="mr-4"
							>Desativar
						</v-btn>
						<v-btn
							color="primary"
							@click="setMercadopagoRefreshToken()"
							:disabled="loading || !mercadopagoCode"
							>Salvar
						</v-btn>
					</v-row>
				</v-col>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import IconMercadopago from '../icons/IconMercadopago.vue'
import IconBase from '../shared/IconBase.vue'

export default {
	props: {
		cliente: Object,
		dialog: Boolean,
	},
	components: {
		IconBase,
		IconMercadopago,
	},
	data: function () {
		return {
			loading: false,
			mercadopagoCode: '',
			mercadopagoClientId: '',
			mercadopagoRedirectUrl: '',
			mercadopagoRefreshToken: '',
			mercadopagoTeste: false,
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
		linkMercadopago() {
			return `https://auth.mercadopago.com/authorization?client_id=${this.mercadopagoClientId}&response_type=code&platform_id=mp&state=35&redirect_uri=${this.mercadopagoRedirectUrl}`
		},
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
		async setMercadopagoRefreshToken() {
			try {
				this.loading = true
				const response = await this.axios.post(`/clientes/${this.cliente.id}/mercadopago/token`, {
					code: this.mercadopagoCode,
					teste: this.mercadopagoTeste,
				})

				this.mercadopagoCode = response.data.refresh_token
				this.$store.dispatch('showSuccess', response.data.msg)
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async loadMercadopago() {
			try {
				this.loading = true
				const response = await this.axios.get('/config/mercadopago')
				this.mercadopagoClientId = response.data.mercadopagoClientId
				this.mercadopagoRedirectUrl = response.data.mercadopagoRedirectUrl
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		copiarLink(link) {
			navigator.clipboard
				.writeText(link)
				.then(() => {
					this.$store.dispatch('showSuccess', 'Link copiado com sucesso!')
				})
				.catch(() => {
					this.$store.dispatch('showError', 'Erro ao copiar link!')
				})
		},
		async desativarMercadopago() {
			try {
				this.loading = true
				const response = await this.axios.post(`/clientes/${this.cliente.id}/mercadopago/desativar`)
				this.$store.dispatch('showSuccess', response.data.msg)
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
				this.dialogClose()
			}
		},
	},
	watch: {
		async dialog() {
			if (this.dialog) {
				await this.loadMercadopago()
				this.mercadopagoTeste = this.cliente.mercadopagoTeste
				this.mercadopagoRefreshToken = this.cliente.mercadopagoRefreshToken
			}
		},
	},
}
</script>