<template>
	<span>
		<img src="@/assets/logo.png" class="img-logo" height="34" width="34" />
		<span class="title ml-3 mr-5">
			Séculos&nbsp;
			<span class="font-weight-light">Admin</span>
		</span>
	</span>
</template>
<script>
	export default {}
</script>
<style>
	.img-logo {
		vertical-align: middle;
	}
</style>