<template>
	<v-dialog v-model="clienteDialog" max-width="300" scrollable>
		<v-card>
			<v-card-title class="mb-2">
				<IconBase width="30" height="30" iconColor="#737373" class="mr-2"><PixIcon /></IconBase>
				Consulta Pix
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-card>
					<DataPicker v-model="datas" @click="loadPix" @close="closeDataPicker" ref="calendario"/>
					<v-card-title class="text-center mr-3 ml-3">
						<p class="ma-0 pa-0">QUANTIDADE DE PIX</p>
					</v-card-title>
					<v-card-text class="text-center text-h1">
						<v-progress-circular v-if="loading" indeterminate color="primary" class="mb-8"></v-progress-circular>
						<p class="contagemPedidos" v-if="!loading">{{ this.quantidade }}</p>
					</v-card-text>
				</v-card>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DataPicker from '../../components/shared/DataPicker.vue'
import PixIcon from '../../components/icons/IconPix.vue'
import IconBase from '../../components/shared/IconBase.vue'

export default {
	props: {
		cpfCnpj: String,
		dialog: Boolean,
	},
	components: { DataPicker, PixIcon, IconBase },
	data: function () {
		return {
			loading: false,
			datas: [],
			quantidade: 0,
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.quantidade = 0
			this.$refs.calendario.date = ''
			this.datas = []
			this.$emit('dialogClose')
		},
		formatarCpfCnpj() {
			return this.cpfCnpj ? this.cpfCnpj.replace(/[-|.|/]/g, '') : ''
		},
		quantidadePix() {
			return 0
		},
		async loadPix() {
			this.quantidade = 0
			try {
				this.loading = true
				const response = await this.axios.get('/pix/porcliente', {
					params: {
						cpfCnpj: this.formatarCpfCnpj(),
						datas: this.datas,
					},
				})

				this.quantidade = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		closeDataPicker(){
			this.datas = []
		}
	},
	watch: {},
}
</script>
