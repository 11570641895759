import { ApiService } from './common/serviceBase';
import { URL_CAD_REGISTRO_TERMINAL } from './common/apiUrl';

const service = new ApiService(`${URL_CAD_REGISTRO_TERMINAL}/rtgrupos/`);

export async function getEmpresas(rtg_id) {
    const response = await service.get(rtg_id + "/empresas");
    return response.data;
}

export async function create(data) {
    const response = await service.post('', data);
    return response.data;
}

export async function update(data) {
    const response = await service.put(data.rtg_id, data);
    return response.data;
}

export async function setqtmaquinas(rtg_id, rtg_qtmaquinas) {
    const response = await service.put(rtg_id + '/setmaquinas', { rtg_qtmaquinas });
    return response.data;
}

export async function createAuto(id_cliente) {
    const response = await service.post("automatico/" + id_cliente);
    return response.data;
}