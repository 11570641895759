<template>
	<div>
		<v-data-table :headers="headers" :items="chaves" disable-pagination hide-default-footer>
			<template v-slot:[`item.createdAt`]="{ item }">{{ convertDate(item.createdAt) + " " + convertTime(item.createdAt)}}</template>
			<template v-slot:[`item.dataVen`]="{ item }">{{ convertDate(item.dataVen) }}</template>
			<template v-slot:[`item.acoes`]="{item}">
				<v-btn @click="copiaChave(item.token)" icon color="primary" title="Copiar Chave de Acesso!">
					<v-icon>file_copy</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>
<script>
export default {
	props: ['chaves'],
	data: () => ({
		headers: [
			{ text: 'Solicitante', value: 'solicitante' },
			{ text: 'Data Criação', value: 'createdAt' },
			{ text: 'Data Vencimento', value: 'dataVen' },
			{ text: 'Versão SIC', value: 'versao' },
			{ text: 'Copiar', value: 'acoes', align: 'end' }
		]
	}),
	methods: {
		copiaChave(token) {
			navigator.clipboard.writeText(token)
			this.$store.dispatch('showSuccess', 'Chave de Acesso copiada para o Clipboard!')
		}
	}
}
</script>