<template>
	<v-dialog v-model="filtrarDialog" max-width="800" scrollable>
		<v-card>
			<v-card-text>
				<v-col>
					<v-card-title class="pa-0"><v-icon>mdi-filter</v-icon>Filtrar</v-card-title>
					<v-row class="pl-1">
						<v-checkbox
							class="pa-2 pt-0"
							:ripple="false"
							hide-details
							label="Visualizar somente clientes ativos (não estão desativados e nem bloqueados)"
							v-model="clientesAtivos"
						></v-checkbox>
						<v-checkbox
							class="pa-2 pt-0"
							:ripple="false"
							hide-details
							label="Sem contas/Não é Filial/Não é VIP"
							v-model="clienteSemContas"
						></v-checkbox>
						<v-checkbox
							class="pa-2 pt-0"
							:ripple="false"
							hide-details
							label="Sem contas esse mês/Não é Filial/Não é VIP"
							v-model="clientesSemContasMes"
						></v-checkbox>
						<v-checkbox
							class="pa-2 pt-0"
							:ripple="false"
							hide-details
							label="Em atraso"
							v-model="clientesAtrasados"
						></v-checkbox>
						<v-checkbox
							class="pa-2 pt-0"
							:ripple="false"
							hide-details
							label="Sem versão/Com contas/Não é Filial"
							v-model="semVersao"
						></v-checkbox>
						<v-checkbox
							class="pa-2 pt-0"
							:ripple="false"
							hide-details
							label="Visualizar somente clientes com px"
							v-model="comPx"
						></v-checkbox>
						<v-checkbox
							class="pa-2 pt-0"
							:ripple="false"
							hide-details
							label="Clientes com versão mínima diferente da versão máxima"
							v-model="versoesDiferentes"
						></v-checkbox>
					</v-row>
					<v-row>
						<v-col>
							<v-combobox
							v-model="cidade"
							:items="cidades"
							@update:search-input="getInputCidade"
							item-text="nome"
							item-value="nome"
							label="Filtrar Cidade"
							solo
							:return-object="false"
							clearable
							hide-details
							class="mb-2"
						></v-combobox>
						</v-col>
					</v-row>
					<v-row class="pl-1">
						<v-col class="pb-0">
							<v-card-title class="pa-0 pb-2"
								><v-icon>mdi-tune</v-icon>Filtrar por PxAcertos</v-card-title
							>
							<v-row class="pl-2">
								<v-radio-group v-model="tipoPx" row class="mt-0" hide-details="">
									<v-radio label="Frente de Loja" value="px_paf"></v-radio>
									<v-radio label="Retaguarda" value="px_ret"></v-radio>
									<v-radio label="Loja" value="loja"></v-radio>
								</v-radio-group>
							</v-row>
						</v-col>
					</v-row>
					<v-row class="pl-1">
						<v-col class="pt-0">
							<v-row>
								<v-col>
									Posição
									<v-text-field
										:disabled="tipoPx == 'todosComPx'"
										label="Posição"
										v-model="posicao"
										solo
										hide-details
									></v-text-field>
								</v-col>
								<v-col>
									Conteúdo
									<v-text-field
										:disabled="tipoPx == 'todosComPx'"
										label="Conteúdo"
										v-model="conteudo"
										solo
										hide-details
									></v-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row class="pl-1">
						<v-col class="pb-0">
							<v-card-title class="pa-0 pb-2">
								<v-icon>mdi-cog</v-icon>Filtrar por Configurações
							</v-card-title>
						</v-col>
					</v-row>
					<v-row class="pl-1">
						<v-col class="pt-0">
							<v-row>
								<v-col>
									Campo
									<v-combobox
										v-model="campo"
										:items="camposConfiguracao"
										@update:search-input="getInputCampo"
										label="Campo"
										solo
										:return-object="false"
										clearable
										hide-details
										class="mb-2"
									></v-combobox>
								</v-col>
								<v-col>
									Operador
									<v-select
										v-model="operadorConfiguracao"
										:items="operadoresConfiguracao"
										single-line
										solo
										hide-details
										label="Operador"
									/>
								</v-col>
								<v-col>
									Valor
									<v-text-field
										:disabled="tipoPx == 'todosComPx'"
										label="Valor"
										v-model="valor"
										solo
										hide-details
									></v-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row>
						<v-spacer />
						<v-btn text color="primary" class="mr-2" @click="limparFiltros">Limpar Filtros</v-btn>
						<v-btn color="primary" @click="filtrar">Filtrar</v-btn>
					</v-row>
				</v-col>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		dialog: Boolean,
		posicaoAtual: String,
		conteudoAtual: String,
		clientesAtivosAtual: Boolean,
		clientesAtrasadosAtual: Boolean,
		clienteSemContasAtual: Boolean,
		semVersaoAtual: Boolean,
		versoesDiferentesAtual: Boolean,
		cidadeAtual: String,
		cidades: Array,
		camposConfiguracao: Array,
	},
	data: function () {
		return {
			tipoPx: this.tipoPxAtual,
			posicao: this.posicaoAtual,
			conteudo: this.posicaoAtual,
			clientesAtivos: this.clientesAtivosAtual,
			clientesAtrasados: this.clientesAtrasadosAtual,
			clienteSemContas: this.clienteSemContasAtual,
			semVersao: this.semVersaoAtual,
			comPx: false,
			versoesDiferentes: this.versoesDiferentesAtual,
			cidade: this.cidadeAtual,
			clientesSemContasMes: this.clientesSemContasMesAtual,
			cidadeInput: '',
			operadorConfiguracao: '',
			campo: '',
			operadoresConfiguracao: ['=', '<>', '>=', '>', '<', '<='],
			valor: '',
			campoInput: ''
		}
	},
	computed: {
		filtrarDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
		configuracao: {
			get() {
				return this.campo && this.operadorConfiguracao
					? `'${this.campo}' ${this.operadorConfiguracao} '${this.valor}'`
					: ''
			},
		},
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
		salvarFiltros() {
			this.$emit(
				'salvarFiltros',
				this.tipoPx,
				this.posicao,
				this.conteudo,
				this.clientesAtivos,
				this.clientesAtrasados,
				this.clienteSemContas,
				this.semVersao,
				this.versoesDiferentes,
				this.cidadeInput,
				this.configuracao,
				this.clientesSemContasMes
			)
		},
		limparFiltros() {
			this.tipoPx =
				this.posicao =
				this.conteudo =
				this.clientesAtivos =
				this.clientesAtrasados =
				this.clienteSemContas =
				this.semVersao =
				this.comPx =
				this.versoesDiferentes =
				this.cidade =
				this.campo =
				this.operadorConfiguracao =
				this.valor =
				this.clientesSemContasMes =
					''
		},
		filtrar() {
			this.dialogClose()
			this.salvarFiltros()
		},
		getInputCidade(value) {
			this.cidadeInput = value
		},
		getInputCampo(value) {
			this.campoInput = value
		},
	},
	watch: {
		comPx() {
			if (this.comPx == true) {
				this.tipoPx = 'todosComPx'
				this.posicao = this.conteudo = ''
			} else this.tipoPx = ''
		},
		tipoPx() {
			if (this.tipoPx != 'todosComPx' && this.comPx == true) this.comPx == false
		},
	},
}
</script>

<style>
</style>