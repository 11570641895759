<template>
	<v-dialog v-model="isDialog" :max-width="600">
		<v-card>
			<v-card-title class="mb-2">
				<v-icon left>mdi-tune</v-icon>
				Novo Token
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text>
				<!-- <v-checkbox v-model="isMiniToken" class="mr-10" label="Mini Token" @click="miniTokenClick"></v-checkbox> -->

				<div class="d-flex align-center">
					<v-btn @click="copiaChave(rtt_token)" icon color="primary" title="Copiar Token!">
						<v-icon>file_copy</v-icon>
					</v-btn>
					<v-text-field label="Token" variant="outlined" readonly class="text-h5"
						v-model="rtt_token"></v-text-field>
				</div>

				<div class="d-flex justify-end">
					<v-btn color="success" class="text-none" size="small" @click="newToken">
						Registrar Token
					</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>

import { guid } from '../../utils/newGuid.js'
import * as rttokensService from '../../services/rttokensService.js'

export default {
	props: {
		rtg_id: String,
		dialog: Boolean,
	}, data: () => ({
		rtt_token: "",
		isMiniToken: false
	}), methods: {
		async newToken() {
			try {
				this.$isLoading(true);
				await rttokensService.create({
					rtg_id: this.rtg_id,
					rtt_token: this.rtt_token
				});

				this.$store.dispatch('showSuccess', 'Token registrado com sucesso!')
				this.dialogClose();
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.$isLoading(false);
			}
		}, dialogClose() {
			this.$emit('dialogClose')
		}, copiaChave(token) {
			navigator.clipboard.writeText(token)
			this.$store.dispatch('showSuccess', 'Token copiado com sucesso!')
		}, miniTokenClick() {
			this.createToken();
		}, createToken() {
			let token = guid();
			this.rtt_token = token;

			if (this.isMiniToken)
				this.rtt_token = token.split('-').slice(0, 2).join('-').toUpperCase();
		}
	}, computed: {
		isDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose();
			}
		}
	}, watch: {
		dialog() {
			this.isMiniToken = false;
			this.createToken();
		}
	}
}
</script>