<template>
	<div>
		<DialogRelatoriosPadroes :dialog="dialog" :relatorioId="relatorioId" @dialogClose="dialogClose" />
		<v-card v-if="userRelatoriosPadroes || userMaster">
			<v-card-title> <v-icon color="primary" left>mdi-file-chart</v-icon>Relatórios Padrões </v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" md="9">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Pesquisar pelo nome do relatório"
							single-line
							hide-details
						></v-text-field>
					</v-col>
					<v-col cols="12" md="3">
						<v-btn
							class="white--text"
							color="primary"
							elevation="9"
							width="100%"
							@click="dialogOpen()"
							large
						>
							<v-icon left> mdi-plus </v-icon>
							Cadastrar Relatório
						</v-btn>
					</v-col>
				</v-row>
				<v-data-table
					:headers="headers"
					:items="relatorios"
					class="elevation-1"
					:loading="loading"
					:search="search"
				>
					<template v-slot:[`item.name`]="{ item }">
						<div @click.stop="relatorioSelected(item)">{{ item.name }}</div>
					</template>
					<template v-slot:[`item.url`]="{ item }">
						<v-btn text :href="item.url" icon>
							<v-icon v-if="item">mdi-download</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import DialogRelatoriosPadroes from '@/components/relatoriosPadroes/DialogRelatoriosPadroes.vue'

export default {
	components: { DialogRelatoriosPadroes },
	data: function () {
		return {
			relatorios: [],
			search: '',
			headers: [
				{ text: 'Nome', value: 'name' },
				{ text: '', value: 'url' },
			],
			dialog: false,
			loading: false,
			relatorioId: '',
		}
	},
	computed: {
		...mapGetters(['userRelatoriosPadroes', 'userMaster']),
	},
	methods: {
		async loadRelatorios() {
			try {
				this.loading = true
				const response = await this.axios.get('/relatoriospadroes')
				this.relatorios = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		loadRelatoriosSearch() {
			this.loadRelatorios()
		},
		async relatorioSelected(relatorio) {
			this.dialog = true
			this.relatorioId = relatorio.id
		},
		dialogOpen(){
			this.dialog = true
		},
		dialogClose() {
			this.relatorioId = ''
			this.dialog = false
			this.loadRelatorios()
		},
	},
	mounted() {
		this.loadRelatorios()
	},
}
</script>
