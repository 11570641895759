import Vue from 'vue'
import loading from 'vuejs-loading-screen'

// https://www.vuescript.com/fullpage-loading-screen-custom-spinner/
Vue.use(loading, {
    // background color
    bg: '#808080ad',
    // spinner icon
    // requires Font Awesome
    icon: 'fas fa-spinner',
    // spinner size:
    // 1 to 5
    size: 5,
    // icon color
    icon_color: '#ffffff',
})

export default loading