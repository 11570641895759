<template>
	<div>
		<ComputadorDialog :dialog="dialog" :computadorId="computadorId" @dialogClose="dialogClose" />
		<v-card>
			<v-card-title>
				<v-icon left color="primary">pageview</v-icon>Lista de Computadores
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="12" md="5">
						<v-text-field
							v-model="cliente"
							append-icon="search"
							label="Pesquisar pelo Nome, Nome Fantasia ou CNPJ do cliente"
							single-line
							hide-details
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="5">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Pesquisar pelo Computador"
							single-line
							hide-details
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="2">
						<v-checkbox v-model="compSeculos" label="Ver SICBR"></v-checkbox>
					</v-col>
				</v-row>
				<v-data-table
					:headers="headers"
					:items="computadores"
					:options.sync="options"
					:server-items-length="total"
					class="elevation-1"
					:loading="loading"
					@click:row="computadorSelected"
					dense
				>
				<template v-slot:[`item.ultimoAcesso`]="{ item }">{{ convertDate(item.ultimoAcesso)  + " " + convertTime(item.ultimoAcesso) }}</template></v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import ComputadorDialog from '../components/computadores/ComputadorDialog'

export default {
	components: { ComputadorDialog },
	data: function () {
		return {
			computadores: [],
			headers: [
				{ text: 'CNPJ', value: 'cliente.cnpj' },
				{ text: 'Cliente Nome', value: 'cliente.clienteNome' },
				{ text: 'Nome', value: 'nome' },
				{ text: 'Versão', value: 'versao' },
				{ text: 'Sistema', value: 'sisOp' },
				{ text: '.NET', value: 'framework' },
				{ text: 'Último Acesso', value: 'ultimoAcesso' },
			],
			search: '',
			loading: false,
			options: {},
			total: 0,
			cliente: '',
			dialog: false,
			computadorId: '',
			compSeculos: false,
		}
	},
	methods: {
		async loadComputadores() {
			try {
				this.loading = true
				const { sortBy, sortDesc, page, itemsPerPage } = this.options

				const response = await this.axios.get('/computadores', {
					params: {
						cliente: this.cliente,
						page,
						limit: itemsPerPage,
						sortBy: sortBy[0],
						sortDesc: sortDesc[0],
						search: this.search,
						vip: this.vip,
						filial: this.filial,
						compSeculos: this.compSeculos
					},
				})
				this.computadores = response.data.computadores
				this.total = response.data.total
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		loadComputadoresSearch() {
			this.options.page = 1
			this.loadComputadores()
		},
		async computadorSelected(computador) {
			this.dialog = true
			this.computadorId = computador.id
		},
		dialogClose() {
			this.computadorId = ''
			this.dialog = false
			this.loadComputadores()
		}
	},
	mounted() {
		this.loadComputadores()
	},
	watch: {
		options: {
			handler() {
				this.loadComputadores()
			},
			deep: true,
		},
		search() {
			this.loadComputadoresSearch()
		},
		cliente() {
			this.loadComputadoresSearch()
		},
		compSeculos() {
			this.loadComputadoresSearch()
		}
	},
}
</script>
