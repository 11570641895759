<template>
	<v-dialog v-model="isDialog" :max-width="600">
		<v-card>
			<v-card-title class="mb-2">
				Banco de Dados
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text>
				<v-text-field label="ID do Database" variant="outlined" v-model="model.rti_id"></v-text-field>

				<v-text-field label="Descrição" variant="outlined" v-model="model.rti_descricao"></v-text-field>

				<div class="d-flex justify-end">
					<v-btn color="success" class="text-none" size="small" @click="saveClick">
						Salvar
					</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>

import * as riddbService from '../../services/riddbService.js'
import { isUUID } from '../../utils/newGuid.js'

export default {
	props: {
		rtg_id: String,
		dialog: Boolean,
	},
	data: () => ({
		editando: false,
		model: {
			rti_id: "",
			rtg_id: "",
			rti_descricao: ""
		}
	}),
	methods: {
		async saveClick() {
			if (this.validaSalvar() == false)
				return;

			try {
				this.$isLoading(true);
				this.model.rtg_id = this.rtg_id;

				await riddbService.create(this.model);

				this.$store.dispatch('showSuccess', 'Registrado com sucesso!')
				this.dialogClose();
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.$isLoading(false);
			}
		}, validaSalvar() {
			if (!this.model.rti_id || this.model.rti_id.length == 0) {
				this.$store.dispatch('showError', 'Informe o ID do banco de dados da Empresa')
				return false
			}
			if (isUUID(this.model.rti_id) == false) {
				this.$store.dispatch('showError', 'ID inválido, não é uma estrutura Guid')
				return false
			}
			if (!this.model.rti_descricao || this.model.rti_descricao.length == 0) {
				this.$store.dispatch('showError', 'Adicione uma Descrição/Nome para o Banco')
				return false
			}
			return true
		}, dialogClose() {
			this.$emit('dialogClose')
		}
	}, computed: {
		isDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose();
			}
		}
	}, watch: {
		dialog() {
			this.model = {};
		}
	}
}
</script>