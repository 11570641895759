<template>
	<v-dialog v-model="clienteDialog" :max-width="getWidth()" scrollable>
		<v-card>
			<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			<v-card-title class="mb-2">
				<v-icon left>mdi-tune</v-icon>
				Consulta PxAcertos
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row align="center" justify="center">
					<v-radio-group v-model="tipoPx" row>
						<v-radio label="Frente de Loja" value="px_paf"></v-radio>
						<v-radio label="Retaguarda" value="px_ret"></v-radio>
						<v-radio label="Loja" value="loja"></v-radio>
						<v-radio label="Usuário" value="usuario"></v-radio>
					</v-radio-group>
				</v-row>
				<v-row align="center" justify="center">
					<v-col v-if="tipoPx == 'loja' || tipoPx == 'usuario'" cols="3">
						Loja
						<v-select
							:items="lojas"
							solo
							label="Loja"
							item-text="loja"
							v-model="loja"
							hide-details
							v-on:change="getUsuarios(loja)"
							return-object
						></v-select>
					</v-col>
					<v-col cols="3" v-if="tipoPx == 'usuario'">
						Usuário
						<v-select
							:items="usuarios"
							solo
							label="Usuário"
							item-text="nome"
							v-model="usuario"
							hide-details
						></v-select>
					</v-col>
					<v-col cols="3">
						Posição
						<v-text-field
							label="Posição"
							v-model="posicao"
							solo
							hide-details
							@keypress.enter="getPx"
						></v-text-field>
					</v-col>
					<v-col cols="3">
						<v-btn color="primary" large @click="getPx" class="mt-6">
							<v-icon>mdi-magnify</v-icon>Pesquisar</v-btn
						>
					</v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-card-title class="primary--text" align="center">{{ px }}</v-card-title>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
	props: {
		id: String,
		dialog: Boolean,
	},
	data: function () {
		return {
			loading: false,
			tipoPx: 'px_paf',
			px: '',
			posicao: '',
			loja: '',
			usuario: '',
			lojas: [],
			usuarios: [],
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.px = ''
			this.posicao = ''
			this.tipoPx = 'px_paf'
			this.loja = ''
			this.usuario = ''

			this.$emit('dialogClose')
		},
		async getPx() {
			try {
				this.loading = true

				if (!this.posicao) {
					this.$store.dispatch('showError', 'Posição não informada')
					return
				}

				const response = await this.axios.get(`/clientes/${this.id}/pxAcerto`, {
					params: {
						posicao: this.posicao,
						tipoPx: this.tipoPx,
						loja: this.loja.loja,
						usuario: this.usuario,
					},
				})

				this.px = response.data.px
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async getLojasUsuarios() {
			const response = await this.axios.get(`/clientes/${this.id}/pxAcerto/lojasUsuarios`)

			this.lojas = response.data.lojasEusuarios
		},
		getUsuarios(loja) {
			if (this.tipoPx == 'usuario') this.usuarios = loja.usuarios
		},
		getWidth() {
			if (this.tipoPx == 'px_paf' || this.tipoPx == 'px_ret') return 530
			else if (this.tipoPx == 'loja') return 550
			else if (this.tipoPx == 'usuario') return 800
		},
	},
	watch: {
		tipoPx() {
			this.px = ''
			this.loja = ''
			this.usuario = ''

			if (this.tipoPx == 'loja' || this.tipoPx == 'usuario') this.getLojasUsuarios()
		},
	},
}
</script>