<template>
	<div>
		<RegistroTerminalDialog :dialog="dialogRegistroTerminal" :cliente="cliente"
			@dialogClose="dialogRegistroTerminalClose" />
		<v-card>
			<v-card-title>
				<v-icon color="primary" left>mdi-radiobox-marked</v-icon>Registro de Terminal
			</v-card-title>
			<v-card-text>
				<v-btn color="primary" :disabled="loading" @click="registroTerminalSeculosClick">
					<v-icon left>mdi-tune</v-icon>Registros para Séculos
				</v-btn>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-text-field v-model="search" append-icon="search"
							label="Pesquisar pelo Nome, Fantasia ou CNPJ" single-line hide-details></v-text-field>
					</v-col>
				</v-row>
				<v-data-table :headers="headers" :items="clientes" :options.sync="options" :server-items-length="total"
					class="elevation-1" :loading="loading" @click:row="clienteSelected">

					<template v-slot:[`item.ativo`]="{ item }">
						<v-icon color="success" v-if="item.ativo">check_circle_outline</v-icon>
						<v-icon color="error" v-else>mdi-close-circle-outline</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import RegistroTerminalDialog from '../components/registroTerminal/RegistroTerminalDialog.vue';

export default {
	components: { RegistroTerminalDialog },
	data: function () {
		return {
			clientes: [],
			search: '',
			headers: [
				{ text: 'CNPJ', value: 'cnpj' },
				{ text: 'Nome', value: 'nome' },
				{ text: 'Fantasia', value: 'nomeFantasia' },
				{ text: 'UF', value: 'uf' },
				{ text: 'Cidade', value: 'cidade' },
				{ text: 'Filial', value: 'filial' },
				{ text: 'Ativo?', value: 'ativo' }
			],
			options: {},
			loading: false,
			total: 0,
			dialogRegistroTerminal: false,
			ativos: true,
			cliente: null
		}
	},
	methods: {
		async loadClientes() {
			try {
				if (this.loading) return

				this.loading = true
				const { sortBy, sortDesc, page, itemsPerPage } = this.options

				const response = await this.axios.get('/clientes', {
					params: {
						page,
						limit: itemsPerPage,
						sortBy: sortBy[0],
						sortDesc: sortDesc[0],
						search: this.search,
						ativos: this.ativos
					}
				})

				this.clientes = response.data.clientes
				this.total = response.data.total
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		}, loadClientesSearch() {
			this.options.page = 1
			this.loadClientes()
		}, async clienteSelected(cliente) {
			this.cliente = cliente;
			this.dialogRegistroTerminal = true
		}, dialogRegistroTerminalClose() {
			this.dialogRegistroTerminal = false
		}, async registroTerminalSeculosClick() {
			try {
				this.loading = true
				const response = await this.axios.get(`/clientes/1`)
				this.cliente = response.data
				this.dialogRegistroTerminal = true
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		}
	}, watch: {
		options: {
			handler() {
				this.loadClientes()
			}, deep: true
		}, search() {
			if (this.search.length > 3 || this.search.length == 0)
				this.loadClientesSearch()
		}
	}
}
</script>