<template>
	<v-dialog v-model="computadorDialog" max-width="1024" scrollable>
		<v-card>
			<v-card-title>
				<v-icon left>computer</v-icon>Dados do Computador				
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<input name="id" :id="computador.id" />
				<v-row>
					<v-col cols="12" sm="2" md="2">
						<v-text-field label="CNPJ" readonly v-model="computador.cliente.cnpj"></v-text-field>
					</v-col>
					<v-col cols="12" sm="5" md="6">
						<v-text-field label="Nome" readonly v-model="computador.cliente.nome"></v-text-field>
					</v-col>
					<v-col cols="12" sm="5" md="4">
						<v-text-field label="Nome Fantasia" readonly v-model="computador.cliente.nomeFantasia"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6" md="2">
						<v-text-field label="ID" readonly v-model="computador.id"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="5">
						<v-text-field label="Nome" readonly v-model="computador.nome"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="5">
						<v-text-field label="Versão" readonly v-model="computador.versao"></v-text-field>
					</v-col>
				</v-row>
                <v-row>
					<v-col cols="12" sm="6" md="8">
						<v-text-field label="Sistema" readonly v-model="computador.sisOp"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4">
						<v-text-field label=".NET" readonly v-model="computador.framework"></v-text-field>
					</v-col>					
				</v-row>
                <v-row>
					<v-col cols="12" sm="6" md="3">
						<v-text-field label="Processador ID" readonly v-model="computador.processadorId"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4">
						<v-text-field label="Processador" readonly v-model="computador.processador"></v-text-field>
					</v-col>
                    <v-col cols="12" sm="6" md="3">
						<v-text-field label="Placa Mãe" readonly v-model="computador.placaMaeId"></v-text-field>
					</v-col>	
                    <v-col cols="12" sm="6" md="2">
						<v-text-field label="RAM" readonly v-model="computador.ram"></v-text-field>
					</v-col>				
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	props: {
		computadorId: String,
		dialog: Boolean,
	},
	data: function () {
		return {
			computador: {
				cliente: {}
			},			
			loading: false
		}
	},
	computed: {
		computadorDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},		
	},
	methods: {
		dialogClose() {
			this.tab = null
			this.$emit('dialogClose')
			this.computador = {
				cliente: {}
			}
		},
		async loadComputador() {
			try {
				this.loading = true
				const response = await this.axios.get(`/computadores/${this.computadorId}`)
				this.computador = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		computadorId() {
			this.loadComputador()
		},		
	},
}
</script>
