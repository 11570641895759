<template>
	<div>
		<ClienteDialog :dialog="dialog" :clienteId="clienteId" @dialogClose="dialogClose" />
		<v-card>
			<v-card-title>
				<v-icon color="primary" left>people</v-icon>Cadastro de Clientes
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-text-field v-model="search" append-icon="search"
							label="Pesquisar pelo Nome, Fantasia ou CNPJ" single-line hide-details></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6" md="1">
						<v-checkbox v-model="ativos" label="Ativos"></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6" md="1">
						<v-checkbox v-model="vip" label="VIP"></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6" md="1">
						<v-checkbox v-model="filial" label="Filial"></v-checkbox>
					</v-col>
					<!-- <v-col cols="12" sm="6" md="2">
						<v-checkbox v-model="semComputadores" label="Lib. Antiga"></v-checkbox>
					</v-col> -->
					<v-col cols="12" sm="6" md="2">
						<v-checkbox v-model="comContas" :disabled="comContasAtrasadas || semContas || comContaVenctoHj"
							label="Com contas"></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6" md="2">
						<v-checkbox v-model="comContasAtrasadas" :disabled="comContas || semContas || comContaVenctoHj"
							label="Em atraso"></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6" md="2">
						<v-checkbox v-model="comContaVenctoHj" :disabled="comContas || semContas || comContasAtrasadas"
							label="Vencto. hoje"></v-checkbox>
					</v-col>
					<v-col cols="12" sm="6" md="2">
						<v-checkbox v-model="semContas" :disabled="comContas || comContasAtrasadas || comContaVenctoHj"
							label="Sem contas"></v-checkbox>
					</v-col>
				</v-row>
				<v-data-table :headers="headers" :items="clientes" :options.sync="options" :server-items-length="total"
					class="elevation-1" :loading="loading" @click:row="clienteSelected">
					<template v-slot:[`item.filial`]="{ item }">
						<v-icon color="success" v-if="isFilial(item.matriz)">check_circle_outline</v-icon>
					</template>
					<template v-slot:[`item.ativo`]="{ item }">
						<v-icon color="success" v-if="item.ativo">check_circle_outline</v-icon>
						<v-icon color="error" v-else>mdi-close-circle-outline</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import ClienteDialog from '@/components/clientes/ClienteDialog'

export default {
	components: { ClienteDialog },
	data: function () {
		return {
			clientes: [],
			search: '',
			headers: [
				{ text: 'CNPJ', value: 'cnpj' },
				{ text: 'Nome', value: 'nome' },
				{ text: 'Fantasia', value: 'nomeFantasia' },
				{ text: 'UF', value: 'uf' },
				{ text: 'Cidade', value: 'cidade' },
				{ text: 'Filial', value: 'filial' },
				{ text: 'Ativo?', value: 'ativo' }
			],
			options: {},
			loading: false,
			total: 0,
			vip: false,
			filial: false,
			dialog: false,
			semComputadores: false,
			comContas: false,
			comContasAtrasadas: false,
			comContaVenctoHj: false,
			semContas: false,
			clienteId: '',
			ativos: true
		}
	},
	methods: {
		async loadClientes() {
			try {
				if (this.loading) return

				this.loading = true
				const { sortBy, sortDesc, page, itemsPerPage } = this.options

				const response = await this.axios.get('/clientes', {
					params: {
						page,
						limit: itemsPerPage,
						sortBy: sortBy[0],
						sortDesc: sortDesc[0],
						search: this.search,
						vip: this.vip,
						filial: this.filial,
						semComputadores: this.semComputadores,
						comContas: this.comContas,
						comContasAtrasadas: this.comContasAtrasadas,
						comContaVenctoHj: this.comContaVenctoHj,
						semContas: this.semContas,
						ativos: this.ativos
					}
				})

				this.clientes = response.data.clientes
				this.total = response.data.total
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		loadClientesSearch() {
			this.options.page = 1
			this.loadClientes()
		},
		async clienteSelected(cliente) {
			this.dialog = true
			this.clienteId = cliente.id
		},
		isFilial(matriz) {
			return matriz && matriz !== ''
		},
		dialogClose() {
			this.clienteId = ''
			this.dialog = false
			this.loadClientes()
		}
	},
	watch: {
		options: {
			handler() {
				this.loadClientes()
			},
			deep: true
		},
		search() {
			if (this.search.length > 3 || this.search.length == 0) this.loadClientesSearch()
		},
		vip() {
			this.loadClientesSearch()
		},
		filial() {
			this.loadClientesSearch()
		},
		semComputadores() {
			this.loadClientesSearch()
		},
		comContas() {
			if (this.comContas) this.semContas = false
			this.loadClientesSearch()
		},
		ativos() {
			this.loadClientesSearch()
		},
		comContasAtrasadas() {
			if (this.comContasAtrasadas) {
				this.semContas = false
				this.comContas = false
			}
			this.loadClientesSearch()
		},
		comContaVenctoHj() {
			if (this.comContaVenctoHj) {
				this.comContasAtrasadas = false
				this.semContas = false
				this.comContas = false
			}
			this.loadClientesSearch()
		},
		semContas() {
			this.loadClientesSearch()
		}
	}
}
</script>
