<template>
	<v-card>
		<v-card-title>
			<v-icon left color="primary">mdi-power-plug</v-icon>Integrações
			<v-spacer></v-spacer>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6">
					<v-card class="pa-3">
						<v-card-title class="pa-0">AgroReceita</v-card-title>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="E-mail"
									:readonly="!editEnabled"
									v-model="config.agroEmail"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="Senha"
									:readonly="!editEnabled"
									v-model="config.agroSenha"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="API Key"
									:readonly="!editEnabled"
									v-model="config.agroApikey"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="Token"
									readonly
									v-model="config.agroToken"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
					</v-card>
					<v-card class="pa-3 mt-3">
						<v-card-title class="pa-0">Mercado Pago</v-card-title>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="Cliente ID (Número da integração)"
									:readonly="!editEnabled"
									v-model="config.mercadopagoClientId"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="Client Secret"
									:readonly="!editEnabled"
									v-model="config.mercadopagoClientSecret"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="Url de Redirecionamento"
									:readonly="!editEnabled"
									v-model="config.mercadopagoRedirectUrl"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
				<v-col sm="12" md="6">
					<v-card class="pa-3">
						<v-card-title class="pa-0">API Placas</v-card-title>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="Token API Placas"
									:readonly="!editEnabled"
									v-model="config.tokenPlacas"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
					</v-card>
					<v-card class="pa-3 mt-3">
						<v-card-title class="pa-0">Consulta CPF(API Brasil)</v-card-title>
						<v-row>
							<v-col cols="12">
								<v-row>
									<v-col>
										<p class="mb-0 label">Tipo de consulta:</p>
										<v-radio-group v-model="config.apibrasilTipo" row class="mt-0" hide-details>
											<v-radio label="Plano Contratado" :value="0" />
											<v-radio label="Por Crédito" :value="1" />
										</v-radio-group>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field
											label="Token API Brasil"
											:readonly="!editEnabled"
											v-model="config.apibrasilToken"
											hide-details
											class="mb-0"
										/>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field
											label="Token Dispositivo"
											:readonly="!editEnabled"
											v-model="config.apibrasilDeviceToken"
											hide-details
										/>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			<v-btn color="primary" v-if="editEnabled" @click="saveConfig" class="mr-1">Salvar</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	data: function () {
		return {
			config: {},
			loading: false,
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userIntegracoes']),
		editEnabled() {
			return !this.loading && (this.userAdmin || this.userIntegracoes)
		},
	},
	methods: {
		async loadConfig() {
			try {
				this.loading = true
				const response = await this.axios.get('/integracoes')
				this.config = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},

		async saveConfig() {
			try {
				this.dialogLoading = true
				await this.axios.put('/integracoes', this.config)
				this.$store.dispatch('showSuccess', 'Configuração salva com sucesso')
				this.loadConfig()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.dialogLoading = false
			}
		},
	},
	mounted() {
		this.loadConfig()
	},
	watch: {
		pixTaxaJuros() {
			this.config.pixTaxaJuros = Number(this.pixTaxaJuros.replace(',', '.'))
		},
	},
}
</script>

<style scoped>
.label {
	color: grey;
}
</style>
