<template>
	<div class="home">
		<v-card>
			<v-card-title>
				<AppLogo />
			</v-card-title>
		</v-card>
	</div>
</template>

<script>
	// @ is an alias to /src
	import AppLogo from '@/components/widgets/AppLogo.vue'

	export default {
		name: 'Home',
		components: {
			AppLogo
		}
	}
</script>
