<template>
	<v-dialog v-model="relatorioDialog" max-width="500" scrollable>
		<DialogDeleteRelatoriosPadroes :dialog="dialogDelete" @dialogClose="dialogDeleteClose" @deletar="deletar"/>
		<v-card>
			<v-card-title>
				<v-icon left>mdi-file-chart</v-icon>Cadastro de Relatório
				<v-spacer />
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row v-if="relatorio.id">
					<v-col>
						<v-text-field
							v-model="relatorio.id"
							label="ID"
							prepend-icon="mdi-identifier"
							hide-details
							type="text"
							readonly
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-file-input label="Arquivo" v-model="arquivo" accept=".rpt" hide-details>
							<template v-slot:selection>
								{{ arquivo.name }}
							</template>
						</v-file-input>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
				<v-spacer v-if="!loading"></v-spacer>
				<v-btn v-if="!loading && relatorio.id" @click="dialogDeleteOpen" color="error">Excluir</v-btn>
				<v-btn color="primary" v-if="!loading" @click="salvar" :disabled="!arquivo || arquivo.size <= 1">Salvar</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DialogDeleteRelatoriosPadroes from './DialogDeleteRelatoriosPadroes.vue'

export default {
	components: { DialogDeleteRelatoriosPadroes },
	props: {
		relatorioId: String,
		dialog: Boolean,
	},

	data: function () {
		return {
			relatorio: {},
			loading: false,
			arquivo: null,
			dialogDelete: false
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userRelatoriosPadroes']),
		...mapState(['user']),
		relatorioDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.relatorio = {}
			this.arquivo = null
			this.$emit('dialogClose')
		},
		async loadCliente() {
			try {
				this.loading = true
				const response = await this.axios.get(`/relatoriospadroes/${this.relatorioId}`)
				this.relatorio = response.data
				this.arquivo = new File([0], this.relatorio.name)
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		validarArquivo(){
			var nomeArquivo = this.arquivo.name
			var extensaoArquivo = nomeArquivo.substring(nomeArquivo.length-3, nomeArquivo.length).toLowerCase()

			if(extensaoArquivo == 'rpt') return true
		},
		salvar() {
			if (this.relatorio.id) {
				this.editar()
			} else {
				this.criar()
			}
		},
		async criar() {
			if(!this.validarArquivo()) return this.$store.dispatch('showError', 'Formato de arquivo inválido!')
			this.loading = true
			let form = new FormData()
			form.append('relatorio', this.arquivo)

			try {
				await this.axios.post(`/relatoriospadroes`, form)
				this.$store.dispatch('showSuccess', 'Relatório criado com sucesso!')
				this.dialogClose()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async editar() {
			this.loading = true
			let form = new FormData()
			form.append('relatorio', this.arquivo)

			try {
				await this.axios.put(`/relatoriospadroes/${this.relatorio.id}`, form)
				this.$store.dispatch('showSuccess', 'Relatório alterado com sucesso!')
				this.dialogClose()
			} catch (error) {
				this.$store.dispatch('showError', error.message)
			} finally {
				this.loading = false
			}
		},
		async deletar() {
			this.loading = true
			try {
				await this.axios.delete(`/relatoriospadroes/${this.relatorio.id}`)
				this.$store.dispatch('showSuccess', 'Relatório deletado com sucesso!')
				this.dialogClose()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		dialogDeleteOpen(){
			this.dialogDelete = true
		},
		dialogDeleteClose() {
			this.dialogDelete = false
		},
	},
	watch: {
		relatorioId() {
			if (this.dialog) this.loadCliente()
		},
	},
}
</script>
