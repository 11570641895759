<template>
	<v-dialog v-model="clienteDialog" max-width="1024" scrollable>
		<DialogPix :dialog="pix" :cpfCnpj="cliente.cnpj" @dialogClose="closeDialogPix" />
		<DialogHistorico :dialog="historico" :id="cliente.id" @dialogClose="closeDialogHistorico" />
		<DialogPxAcertos :dialog="pxAcertos" :id="cliente.id" @dialogClose="closeDialogPxAcertos" />
		<DialogPlug4Market :dialog="plug4Market" :cliente="cliente" @dialogClose="closeDialogPlug4Market" />
		<DialogConfiguracoes :dialog="configuracoes" :id="cliente.id" @dialogClose="closeDialogConfiguracoes" />
		<DialogAplicativos :dialog="aplicativos" :id="cliente.id" @dialogClose="setDialogAplicativos" :cliente="cliente"
			@loadCliente="loadCliente" />
		<DialogConsultaCpf :dialog="consultaCpf" :id="cliente.id" @dialogClose="setDialogConsultaCpf" :cliente="cliente"
			@loadCliente="loadCliente" />
		<DialogMercadoPago :dialog="mercadopago" :id="cliente.id" @dialogClose="setDialogMercadoPago"
			:cliente="cliente" />
		<v-card>
			<v-card-title class="pb-0 red--text justify-center" color="error"
				v-if="!loading && !cliente.ativo">DESATIVADO</v-card-title>
			<v-card-title>
				<v-icon left>person</v-icon>Cadastro de Cliente
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-icon right v-on="on">mdi-identifier</v-icon>
					</template>
					<span>{{ 'ID: ' + cliente.id }}</span>
					<br />
					<span>{{ ' ID INTERNO SIC: ' + cliente.sicidinterno }}</span>
				</v-tooltip>
				<v-tooltip bottom v-if="filial">
					<template v-slot:activator="{ on }">
						<v-icon right color="warning" v-on="on">warning</v-icon>
					</template>
					<span>Filial</span>
				</v-tooltip>
				<v-spacer />
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="3" md="3">
						<v-text-field label="CNPJ" readonly v-model="cliente.cnpj"></v-text-field>
					</v-col>
					<v-col cols="12" sm="5" md="5">
						<v-text-field label="Razão Social" readonly v-model="cliente.nome"></v-text-field>
					</v-col>
					<v-col cols="12" sm="4" md="4">
						<v-text-field label="Nome Fantasia" readonly v-model="cliente.nomeFantasia"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6" md="5">
						<v-text-field label="Cidade" readonly v-model="cliente.cidade"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="1">
						<v-text-field label="UF" readonly v-model="cliente.uf"></v-text-field>
					</v-col>
					<v-col cols="12" :sm="filial ? 5 : 6" :md="filial ? 5 : 6">
						<v-text-field label="E-mail" readonly v-model="cliente.email"></v-text-field>
					</v-col>
					<v-col v-if="filial">
						<v-text-field label="Versão" readonly text-color="primary"
							v-model="cliente.versaoMaxima"></v-text-field>
					</v-col>
				</v-row>
				<v-row v-if="filial">
					<v-col cols="12" sm="12" md="12">
						<v-btn color="primary" small block @click="loadMatriz(cliente.id)">Carregar Matriz</v-btn>
					</v-col>
				</v-row>
				<v-row v-else-if="userAdmin">
					<v-col cols="12" sm="3" md="3">
						<v-text-field label="Carência"
							hint="Quantidade de dias após o vencimento esse cliente irá estar desbloqueado!"
							v-model="cliente.carencia" type="number" :readonly="!userAdmin"
							:disabled="cliente.bloqueado" @input="setCarencia"></v-text-field>
					</v-col>
					<v-col cols="12" sm="3" md="3">
						<v-text-field label="Validade da Chave em Dias"
							hint="Quantidade de dias que esse cliente por ficar sem validar a chave online!"
							v-model="cliente.diasOffline" type="number" :readonly="!userAdmin"
							:disabled="cliente.bloqueado" @input="setDiasOffline"></v-text-field>
					</v-col>
					<v-col cols="12" sm="4" md="2">
						<v-menu v-model="menuDataCombinada" :close-on-content-click="false" :nudge-right="40"
							transition="scale-transition" offset-y width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="dataCombinada" label="Data combinada" persistent-hint
									prepend-icon="event" v-on="on"
									:disabled="!userAdmin || cliente.bloqueado || !cliente.ativo"></v-text-field>
							</template>
							<v-date-picker v-model="date" @input="menuDataCombinada = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col v-if="!filial">
						<v-text-field label="Versão" readonly text-color="primary"
							v-model="cliente.versaoMaxima"></v-text-field>
					</v-col>
					<v-col md="3" align-self="center" v-if="userAdmin">
						<v-btn color="primary" text block :disabled="loading || !cliente.ativo" @click="addChave">
							<v-icon left>mdi-key-variant</v-icon>Gerar Chave
						</v-btn>
					</v-col>
					<!-- <v-col cols="12" sm="3" md="3" align-self="center">
						<v-btn
							:color="siclock == 'Nova' ? 'green' : siclock == 'Antiga' ? 'error' : 'primary'"
							text
							block
							:disabled="loading"
							@click="tipoLiberacao"
						>
							<v-icon left :color="siclock == 'Nova' ? 'green' : siclock == 'Antiga' ? 'red' : 'blue'"
								>lock</v-icon
							>{{ siclock }}
						</v-btn>
					</v-col> -->
				</v-row>
				<v-row justify="space-between">
					<v-col align-self="center" v-if="userAdmin">
						<v-btn color="primary" text block :disabled="loading" @click="openDialogPix">
							<IconBase class="mr-2">
								<PixIcon />
							</IconBase> PIX
						</v-btn>
					</v-col>
					<v-col align-self="center" v-if="userAdmin">
						<v-btn color="primary" text block :disabled="loading" @click="openDialogHistorico">
							<v-icon left>mdi-clock-time-five</v-icon> Histórico
						</v-btn>
					</v-col>

					<v-col align-self="center" v-if="userAdmin || userPxAcertos">
						<v-btn color="primary" text block :disabled="loading" @click="openDialogPxAcertos">
							<v-icon left>mdi-tune</v-icon>PxAcertos
						</v-btn>
					</v-col>
					<v-col align-self="center" v-if="userAdmin || userPlugmarket">
						<v-btn color="primary" text block :disabled="loading" @click="openDialogPlug4Market">
							<v-icon left>mdi-cart</v-icon>Plug4Market
						</v-btn>
					</v-col>
				</v-row>
				<v-row justify="space-between">
					<v-col align-self="center" v-if="userAdmin">
						<v-btn color="primary" text block :disabled="loading" @click="openDialogConfiguracoes">
							<v-icon left>mdi-cog</v-icon>Configurações
						</v-btn>
					</v-col>
					<v-col align-self="center" v-if="userAdmin || userAplicativos">
						<v-btn color="primary" text block :disabled="loading" @click="setDialogAplicativos">
							<v-icon left>mdi-cellphone</v-icon>Aplicativos
						</v-btn>
					</v-col>
					<v-col align-self="center" v-if="userAdmin || userConsultacpf">
						<v-btn color="primary" text block :disabled="loading" @click="setDialogConsultaCpf">
							<v-icon left>mdi-magnify</v-icon>Consulta CPF
						</v-btn>
					</v-col>
					<v-col align-self="center" v-if="userAdmin || userMercadopago">
						<!-- <IconMercadopago /> -->
						<v-btn color="primary" text block :disabled="loading" @click="setDialogMercadoPago">
							<IconBase class="mr-2" :width="30" :height="30">
								<IconMercadopago />
							</IconBase>
							Mercado Pago
						</v-btn>
					</v-col>
				</v-row>

				<v-row justify="space-around">
					<v-checkbox v-if="userAdmin" label="Bloqueado" v-model="bloqueado"
						:disabled="loading || !userAdmin || !cliente.ativo"></v-checkbox>
					<v-checkbox v-if="userAdmin" label="Vip" v-model="vip"
						:disabled="loading || !userMaster || !cliente.ativo"></v-checkbox>
					<v-checkbox v-if="userAdmin" label="Notificar a Vencer" v-model="notificarVencimento"
						:disabled="loading || !userAdmin || !cliente.ativo"></v-checkbox>
					<v-checkbox v-if="userAdmin" label="Notificar Vencidas" v-model="notificarVencidas"
						:disabled="loading || !userAdmin || !cliente.ativo"></v-checkbox>
					<!-- <v-checkbox
						label="Notificar Bloqueio"
						v-model="notificarBloqueio"
						:disabled="loading || !userAdmin"
					></v-checkbox> -->
					<v-checkbox label="Desabilitar Restart" v-model="restartSystem" color="error"
						:disabled="loading || !userMaster || !cliente.ativo" v-if="userMaster"></v-checkbox>
					<v-checkbox label="Regras Tributárias" v-model="usarRegrasTrib"
						:disabled="loading || (!userAdmin && !userRegrasTrib) || !cliente.ativo"
						v-if="userAdmin || userRegrasTrib"></v-checkbox>
				</v-row>
				<v-row justify="space-around">
					<!-- <v-checkbox
						label="Bloquear Requisições"
						v-model="bloquearRequisicao"
						:disabled="loading || !userAdmin"
						v-if="userAdmin"
					></v-checkbox> -->
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-tabs v-model="tab" grow optional>
							<v-tab :disabled="tituloContas" key="contasTab" v-if="userAdmin" @click="loadContas">
								<v-icon left>assignment</v-icon>Contas
							</v-tab>
							<v-tab key="computadoresTab" @click="loadComputadores">
								<v-icon left>computer</v-icon>Computadores
							</v-tab>
							<v-tab key="notificationsTab" v-if="userAdmin" @click="loadNotificacoes">
								<v-icon left>message</v-icon>Notificações
							</v-tab>
							<v-tab key="chavesTab" v-if="userAdmin" @click="loadChaves">
								<v-icon left>mdi-key-variant</v-icon>Chaves
							</v-tab>
							<v-tabs-items v-model="tab">
								<v-tab-item key="contasTab" v-if="userAdmin">
									<Contas :contas="contas" />
								</v-tab-item>
								<v-tab-item key="computadoresTab">
									<Computadores :computadores="computadores" @computadorChanged="loadComputadores" />
								</v-tab-item>
								<v-tab-item key="notificationsTab" v-if="userAdmin">
									<Notifications :notificacoes="notificacoes" :clienteId="cliente.id"
										@notificationChanged="loadNotificacoes" />
								</v-tab-item>
								<v-tab-item key="chavesTab">
									<Chaves :chaves="chaves" />
								</v-tab-item>
							</v-tabs-items>
						</v-tabs>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Contas from './Contas.vue'
import Computadores from './Computadores.vue'
import Notifications from './Notifications.vue'
import Chaves from './Chaves.vue'
import DialogPix from './DialogPix.vue'
import DialogHistorico from './DialogHistoricoAlteracoes.vue'
import PixIcon from '../icons/IconPix.vue'
import IconBase from '../shared/IconBase.vue'
import DialogPxAcertos from './DialogPxAcertos.vue'
import DialogPlug4Market from './DialogPlug4Market.vue'
import DialogConfiguracoes from './DialogConfiguracoes.vue'
import DialogAplicativos from './DialogAplicativos.vue'
import DialogConsultaCpf from './DialogConsultaCpf.vue'
import IconMercadopago from '../icons/IconMercadopago.vue'
import DialogMercadoPago from './DialogMercadoPago.vue'

export default {
	props: {
		clienteId: String,
		dialog: Boolean,
	},
	components: {
		Contas,
		Computadores,
		Notifications,
		Chaves,
		DialogPix,
		PixIcon,
		IconBase,
		DialogHistorico,
		DialogPxAcertos,
		DialogPlug4Market,
		DialogConfiguracoes,
		DialogAplicativos,
		DialogConsultaCpf,
		IconMercadopago,
		DialogMercadoPago,
	},
	data: function () {
		return {
			cliente: {},
			menuDataCombinada: null,
			tab: null,
			loading: false,
			date: null,
			contas: [],
			computadores: [],
			notificacoes: [],
			chaves: [],
			siclock: 'Tipo Liberação',
			pix: false,
			historico: false,
			pxAcertos: false,
			plug4Market: false,
			configuracoes: false,
			aplicativos: false,
			consultaCpf: false,
			mercadopago: false,
		}
	},
	computed: {
		...mapGetters([
			'userAdmin',
			'userMaster',
			'userRegrasTrib',
			'userPxAcertos',
			'userAplicativos',
			'userConsultacpf',
			'userPlugmarket',
			'userMercadopago'
		]),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
		dataCombinada() {
			return this.convertDate(this.cliente.dataCombinada)
		},
		filial() {
			return this.cliente.matriz && this.cliente.matriz !== ''
		},
		bloqueado: {
			get() {
				return this.cliente.bloqueado
			},
			set(val) {
				this.cliente.bloqueado = val
				this.setBloqueado()
			},
		},
		vip: {
			get() {
				return this.cliente.vip
			},
			set(val) {
				this.cliente.vip = val
				this.setVip()
			},
		},
		notificarVencimento: {
			get() {
				return this.cliente.notificarVencimento
			},
			set(val) {
				this.cliente.notificarVencimento = val
				this.setNotificarVencimento()
			},
		},
		notificarVencidas: {
			get() {
				return this.cliente.notificarVencidas
			},
			set(val) {
				this.cliente.notificarVencidas = val
				this.setNotificarVencidas()
			},
		},
		notificarBloqueio: {
			get() {
				return this.cliente.notificarBloqueio
			},
			set(val) {
				this.cliente.notificarBloqueio = val
				this.setNotificarBloqueio()
			},
		},
		restartSystem: {
			get() {
				return this.cliente.disablesystemcheck
			},
			set(val) {
				this.cliente.disablesystemcheck = val
				this.setRestartSystem()
			},
		},
		usarRegrasTrib: {
			get() {
				return this.cliente.usarRegrasTrib
			},
			set(val) {
				this.cliente.usarRegrasTrib = val
				this.setUsarRegrasTrib()
			},
		},
		bloquearRequisicao: {
			get() {
				return this.cliente.bloquearRequisicao
			},
			set(val) {
				this.cliente.bloquearRequisicao = val
				this.setBloquearRequisicao()
			},
		},
		tituloContas() {
			return this.cliente && this.cliente.contas && this.cliente.contas.length == 0
		},
	},
	methods: {
		dialogClose() {
			this.tab = null
			this.$emit('dialogClose')
		},
		openDialogPix() {
			this.pix = true
		},
		openDialogPlug4Market() {
			this.plug4Market = true
		},
		openDialogPxAcertos() {
			this.pxAcertos = true
		},
		closeDialogPix() {
			this.pix = false
		},
		closeDialogPlug4Market() {
			this.plug4Market = false
		},
		closeDialogPxAcertos() {
			this.pxAcertos = false
		},
		openDialogHistorico() {
			this.historico = true
		},
		closeDialogHistorico() {
			this.historico = false
		},
		openDialogConfiguracoes() {
			this.configuracoes = true
		},
		closeDialogConfiguracoes() {
			this.configuracoes = false
		},
		setDialogAplicativos() {
			this.loadCliente()
			this.aplicativos = !this.aplicativos
		},
		setDialogConsultaCpf() {
			this.loadCliente()
			this.consultaCpf = !this.consultaCpf
		}, setDialogMercadoPago() {
			this.loadCliente()
			this.mercadopago = !this.mercadopago
		}, async loadCliente() {
			try {
				this.loading = true
				const response = await this.axios.get(`/clientes/${this.clienteId}`)
				this.cliente = response.data
				this.siclock = 'Tipo Liberação'
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async loadContas() {
			try {
				this.loading = true
				const response = await this.axios.get(`/clientes/${this.cliente.id}/contas`)
				this.contas = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		}, async loadComputadores() {
			try {
				this.loading = true
				const response = await this.axios.get(`/clientes/${this.cliente.id}/computadores`)
				this.computadores = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		}, async tipoLiberacao() {
			try {
				this.loading = true
				const response = await this.axios.get(`/clientes/${this.cliente.id}/computadores`)
				this.siclock = response.data.length == 0 ? 'Antiga' : 'Nova'
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async loadNotificacoes() {
			try {
				this.loading = true
				const response = await this.axios.get(`/clientes/${this.cliente.id}/notificacoes`)
				this.notificacoes = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async loadChaves() {
			try {
				this.loading = true
				const response = await this.axios.get(`/clientes/${this.cliente.id}/chaves`)
				this.chaves = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async loadMatriz() {
			try {
				this.loading = true
				const response = await this.axios.get(`/clientes/${this.clienteId}/matriz`)
				this.cliente = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		setCarencia() {
			this.setField('carencia', 'Carência alterada com sucesso!')
		},
		setDiasOffline() {
			this.setField('diasOffline', 'Dias Offline alterado com sucesso!')
		},
		async setDataCombinada() {
			await this.setField('dataCombinada', 'Data Combinada alterada com sucesso!')
			this.loadCliente()
		},
		setBloqueado() {
			this.setField('bloqueado', 'Bloqueio alterado com sucesso!')
		},
		async setVip() {
			await this.setField('vip', 'Vip alterado com sucesso!')
			this.loadCliente()
		},
		setNotificarVencimento() {
			this.setField('notificarVencimento', 'Notificação a vencer alterada com sucesso!')
		},
		setNotificarVencidas() {
			this.setField('notificarVencidas', 'Notificação de contas vencidas alterada com sucesso!')
		},
		setNotificarBloqueio() {
			this.setField('notificarBloqueio', 'Notificação de Bloqueio alterada com sucesso!')
		},
		setRestartSystem() {
			this.setField('disablesystemcheck', 'RestartSystem alterado com sucesso!')
		},
		setUsarRegrasTrib() {
			this.setField('usarRegrasTrib', '"Usar Regras Tributárias" alterado com sucesso!')
		},
		setBloquearRequisicao() {
			this.setField('bloquearRequisicao', '"Bloquear Requisições" alterada com sucesso!')
		},
		async setField(field, msg) {
			try {
				this.loading = true
				const params = {}
				params[field] = this.cliente[field]
				await this.axios.put(`/clientes/${this.cliente.id}/${field}`, params)
				this.$store.dispatch('showSuccess', msg)
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async addChave() {
			try {
				this.loading = true
				const response = await this.axios.get(
					`/chaves/new?cnpj=${this.cliente.cnpj}&solicitante=${this.user.nome}&versao=Gerada pelo Admin`
				)
				navigator.clipboard.writeText(response.data.token)
				this.$store.dispatch('showSuccess', 'Chave de Acesso copiada para o Clipboard!')
				this.loadCliente()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		clienteId() {
			this.loadCliente()
		},
		date() {
			this.cliente.dataCombinada = this.date
			this.setDataCombinada()
		},
	},
}
</script>
