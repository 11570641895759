<template>
	<v-dialog v-model="clienteDialog" max-width="700" scrollable>
		<v-card>
			<v-card-title>
				<v-icon left>mdi-cog</v-icon>
				Configurações
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<div v-if="lojas.length > 1" class="lojas">
					<div v-for="loja in lojas" :key="loja.id" >
						<v-btn color="primary" class="mr-2" @click="selecionarLoja(loja)">{{ loja.loja }}</v-btn>
					</div>
				</div>
				<v-row>
					<v-col>
						<v-text-field
							hide-details=""
							solo
							dense
							placeholder="Pesquisar"
							v-model="search"
							append-icon="search"
						></v-text-field>
					</v-col>
				</v-row>
				<v-card>
					<v-data-table
						:headers="headers"
						:items="lojaFormatada"
						:search="search"
						class="elevation-1"
						:loading="loading"
					>
					<template v-slot:[`item.campo`]="{ item }"> <b>{{ item.campo }}</b></template>
					</v-data-table>
				</v-card>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
	props: {
		id: String,
		dialog: Boolean,
	},
	data: function () {
		return {
			loading: false,
			lojas: [],
			loja: {},
			search: '',
			headers: [
				{ text: 'Campo', value: 'campo' },
				{ text: 'Valor', value: 'valor' },
			],
			options: {},
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
		lojaFormatada: {
			get() {
				if (!this.loja.id) return []

				const campos = Object.keys(this.loja.configuracoes)

				let teste = []

				campos.map((campo) => {
					teste.push({
						campo,
						valor: this.loja.configuracoes[campo],
					})
				})

				return teste
			},
		},
	},
	methods: {
		dialogClose() {
			this.search = ''
			this.lojas = []
			this.loja = {}
			this.$emit('dialogClose')
		},
		async loadConfiguracoes() {
			try {
				this.loading = true

				const response = await this.axios.get(`/clientes/${this.id}/lojas`, {})

				this.lojas = response.data
				if(this.lojas.length > 0) this.loja = this.lojas[0]
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		selecionarLoja(loja) {
			this.loja = loja
		},
	},
	watch: {
		dialog() {
			if (this.dialog) this.loadConfiguracoes()
		},
	},
}
</script>

<style scoped>

.lojas{
	display: flex;
}
</style>

