<template>
	<div>
		<v-dialog v-model="dialog" max-width="1024" scrollable>
			<v-card>
				<v-card-title>
					Cadastro de Mensagem
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="
							dialog = false
							tipoMensagem = 'versao'
						"
					>
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-radio-group row v-model="tipoMensagem">
						<v-radio
							label="Por Versão"
							color="primary"
							:disabled="dialogLoading"
							:readonly="!userAdmin"
							value="versao"
						></v-radio>
						<v-radio
							label="Por CNPJ + Versão"
							color="primary"
							:disabled="dialogLoading"
							:readonly="!userAdmin"
							value="cnpj"
						></v-radio>
						<div v-if="tipoMensagem == 'cnpj'" class="primary--text font-weight-bold">Essa opção só terá efeito se EXE SIC a partir: 22.4.6</div>
					</v-radio-group>
					<v-radio-group row v-model="versao.tipo">
						<v-radio
							label="Notificar"
							color="primary"
							:disabled="dialogLoading"
							:readonly="!userAdmin"
						></v-radio>
						<v-radio
							label="Alertar"
							color="warning"
							:disabled="dialogLoading"
							:readonly="!userAdmin"
						></v-radio>
						<v-radio
							label="Bloquear"
							color="red"
							:disabled="dialogLoading"
							:readonly="!userAdmin"
						></v-radio>
					</v-radio-group>
					<v-row>
						<v-col cols="12" sm="6" md="4" v-if="tipoMensagem == 'cnpj'">
							<v-text-field
								v-model="versao.cnpj"
								label="CNPJ"
								prepend-icon="label"
								type="text"
								required
								:disabled="dialogLoading"
								:readonly="!userAdmin"
							/>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field
								v-model="versao.versaoMaior"
								label="Versão Maior >="
								prepend-icon="label"
								type="text"
								required
								:disabled="dialogLoading"
								:readonly="!userAdmin"
							/>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field
								v-model="versao.versaoMenor"
								label="Versão Menor <="
								prepend-icon="label"
								type="text"
								required
								:disabled="dialogLoading"
								:readonly="!userAdmin"
							/>
						</v-col>
						<v-col cols="12" sm="6" md="8">
							<v-text-field
								v-model="versao.msg"
								label="Mensagem"
								prepend-icon="message"
								type="text"
								required
								:disabled="dialogLoading"
								:readonly="!userAdmin"
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn color="red" dark v-if="exibeExcluir" @click="removeVersao">Excluir</v-btn>
					<v-progress-linear v-if="dialogLoading" indeterminate color="primary"></v-progress-linear>
					<v-spacer v-if="!dialogLoading"></v-spacer>
					<v-btn v-if="!dialogLoading" @click="dialog = false">Sair</v-btn>
					<v-btn color="primary" v-if="exibeSalvar" @click="saveVersao">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-card>
			<v-card-title>
				<v-icon left color="primary">message</v-icon>Cadastro de Mensagens
				<v-spacer></v-spacer>
				<v-btn icon color="primary" x-large @click="newVersao">
					<v-icon>add_circle</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-data-table
					:headers="headers"
					:items="versoes"
					class="elevation-1"
					:loading="loading"
					disable-pagination
					hide-default-footer
					@click:row="loadVersao"
				>
					<template v-slot:[`item.tipo`]="{ item }">
						<v-icon color="primary" v-if="item.tipo === 0" title="Notificar">sms</v-icon>
						<v-icon color="warning" v-if="item.tipo === 1" title="Alertar">error</v-icon>
						<v-icon color="red" v-if="item.tipo === 2" title="Bloquear">block</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	data: function () {
		return {
			versoes: [],
			headers: [
				{ text: 'CNPJ', value: 'cnpj' },
				{ text: 'Versão Maior', value: 'versaoMaior' },
				{ text: 'Versão Menor', value: 'versaoMenor' },
				{ text: 'Mensagem', value: 'msg' },
				{ text: 'Tipo', value: 'tipo' },
			],
			loading: false,
			dialogLoading: false,
			dialog: null,
			versao: {},
			tipoMensagem: 'versao',
		}
	},
	computed: {
		...mapGetters(['userAdmin']),
		exibeExcluir() {
			return !this.loading && this.versao.id && this.userAdmin
		},
		exibeSalvar() {
			return !this.loading && this.userAdmin
		},
	},
	methods: {
		async loadVersoes() {
			try {
				this.loading = true
				const response = await this.axios.get('/versoes')
				this.versoes = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async loadVersao(versao) {
			try {
				this.dialog = true
				this.versao = versao
				this.loadingDialog = true
				const response = await this.axios.get(`/versoes/${this.versao.id}`)
				this.versao = response.data

				if (this.versao.cnpj) this.tipoMensagem = 'cnpj'
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loadingDialog = false
			}
		},
		newVersao() {
			this.dialog = true
			this.versao = {}
		},
		async saveVersao() {
			try {
				if (this.tipoMensagem == 'versao') this.versao.cnpj = ''
				this.dialogLoading = true
				if (this.versao.id) {
					await this.axios.put(`/versoes/${this.versao.id}`, this.versao)
				} else {
					await this.axios.post('/versoes', this.versao)
				}
				this.$store.dispatch('showSuccess', 'Mensagens salva com sucesso')
				this.dialog = false
				this.loadVersoes()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.dialogLoading = false
				this.tipoMensagem = 'versao'
			}
		},
		async removeVersao() {
			try {
				this.dialogLoading = true
				await this.axios.delete(`/versoes/${this.versao.id}`)
				this.$store.dispatch('showSuccess', 'Mensagens excluída com sucesso!')
				this.dialog = false
				this.loadVersoes()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.dialogLoading = false
			}
		},
	},
	mounted() {
		this.loadVersoes()
	},
	watch: {
		search() {
			this.loadVersoes()
		},
	},
}
</script>
