<template>
	<v-card>
		<v-card-title>
			<v-icon left color="primary">settings</v-icon>Configurações
			<v-spacer></v-spacer>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="6">
					<v-card class="pa-3">
						<v-card-title class="pa-0">Geral</v-card-title>
						<v-row>
							<v-col cols="6">
								<v-text-field
									type="number"
									label="Carência Padrão"
									:readonly="!editEnabled"
									v-model="config.carenciaPadrao"
									hide-details
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									type="number"
									label="Dias Offline Padrão"
									:readonly="!editEnabled"
									v-model="config.diasOfflinePadrao"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="4">
								<v-text-field
									type="number"
									label="Carência Máxima"
									:readonly="!editEnabled"
									v-model="config.carenciaMax"
									hide-details
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-text-field
									type="number"
									label="Dias Offline Máximo"
									:readonly="!editEnabled"
									v-model="config.diasOfflineMax"
									hide-details
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-text-field
									type="number"
									label="Dias Data Combinada Máxima"
									:readonly="!editEnabled"
									v-model="config.dataCombinadaMax"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
				<v-col cols="6">
					<v-card class="pa-3">
						<v-card-title class="pa-0">Recebimento PIX</v-card-title>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="ID (Account ID)"
									:readonly="!editEnabled"
									v-model="config.pixAccountid"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="Client ID"
									:readonly="!editEnabled"
									v-model="config.pixClientid"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									label="Client Secret"
									:readonly="!editEnabled"
									v-model="config.pixClientsecret"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="4">
								<v-text-field
									label="Taxa de juros ao dia (%)"
									:readonly="!editEnabled"
									v-model="pixTaxaJuros"
									hide-details
									v-mask="['#,####']"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-text-field
									label="Carência da multa (dias)"
									:readonly="!editEnabled"
									v-model="config.pixTaxaMultaDias"
									hide-details
									v-mask="['##']"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-text-field
									label="Taxa da multa (%)"
									:readonly="!editEnabled"
									v-model="config.pixTaxaMulta"
									hide-details
									v-mask="['##']"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<!-- <v-row>
				<v-col cols="12" sm="6" md="4">
					<v-text-field
						type="text"
						label="Notificação De Vencimento"
						:readonly="!editEnabled"
						v-model="config.lembreteMsg"
					></v-text-field>
				</v-col>
				<v-col cols="12" sm="6" md="4">
					<v-text-field
						type="text"
						label="Notificação Bloqueio"
						:readonly="!editEnabled"
						v-model="config.bloqueioMsg"
					></v-text-field>
				</v-col>
				<v-col cols="12" sm="6" md="4">
					<v-text-field
						type="text"
						label="Notificação Data Combinada"
						:readonly="!editEnabled"
						v-model="config.dataCombinadaMsg"
					></v-text-field>
				</v-col>
			</v-row> -->
		</v-card-text>
		<v-card-actions>
			<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			<v-btn color="primary" v-if="editEnabled" @click="saveConfig" class="mr-1">Salvar</v-btn>
			<DataCombinadaDialog v-if="!loading" />
			<!-- <v-spacer v-if="!loading"></v-spacer> -->
		</v-card-actions>
	</v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import DataCombinadaDialog from '../components/clientes/DataCombinada'

export default {
	components: { DataCombinadaDialog },
	data: function () {
		return {
			config: {},
			loading: false,
			pixTaxaJuros: 0,
		}
	},
	computed: {
		...mapGetters(['userMaster']),
		editEnabled() {
			return !this.loading && this.userMaster
		},
	},
	methods: {
		async loadConfig() {
			try {
				this.loading = true
				const response = await this.axios.get('/config')
				if (response.data) {
					this.config = response.data
					this.pixTaxaJuros = this.config.pixTaxaJuros.toString().replace('.', ',')
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},

		async saveConfig() {
			try {
				this.dialogLoading = true
				await this.axios.put('/config', this.config)
				this.$store.dispatch('showSuccess', 'Configuração salva com sucesso')
				this.loadConfig()
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.dialogLoading = false
			}
		},
	},
	mounted() {
		this.loadConfig()
	},
	watch: {
		pixTaxaJuros() {
			this.config.pixTaxaJuros = Number(this.pixTaxaJuros.replace(',', '.'))
		},
	},
}
</script>
