import { ApiService } from './common/serviceBase';
import { URL_CAD_REGISTRO_TERMINAL } from './common/apiUrl';

const service = new ApiService(`${URL_CAD_REGISTRO_TERMINAL}/riddb/`);

export async function getAll(rtg_id) {
    const response = await service.get(rtg_id);
    return response.data;
}

export async function create(data) {
    const response = await service.post('', data);
    return response;
}

export async function remove(rti_id) {
    const response = await service.delete(rti_id);
    return response;
}
