<template>
    <v-dialog v-model="isDialog" max-width="1024" scrollable>
        <NewTokenDialog :dialog="dialogNewToken" @dialogClose="dialogNewTokenClose" :rtg_id="rtgrupos.rtg_id" />

        <v-card>
            <v-card-title class="mb-2">

                <span v-if="isSeculos()" class="text-h5 font-weight-black">Registros para Séculos</span>
                <span v-else>
                    <v-icon left>mdi-tune</v-icon>
                    Registro de Terminal</span>

                <v-spacer></v-spacer>
                <v-btn icon @click="dialogClose">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Quantidade de Máquinas" v-model="rtgrupos.rtg_qtmaquinas" type="number"
                            :disabled="!user.usuTerminalQtmaquinasModificar"
                            @input="rtg_qtmaquinasChanged"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-tabs v-model="tab" grow optional>
                            <v-tab key="tabTokens" @click="loadTokens">
                                <v-icon left>lock</v-icon>Tokens
                            </v-tab>
                            <v-tab key="tabEmpresas" @click="loadEmpresas" v-if="isSeculos() == false">
                                <v-icon left>people</v-icon>Empresas
                            </v-tab>
                            <v-tab key="tabConexoesSeguras" @click="loadConexoes" :disabled="false">
                                <v-icon left>mdi-power-plug</v-icon>Conexões Permitidas
                            </v-tab>
                            <v-tab key="tabDatabase" @click="loadDatabase" v-if="isSeculos() == false">
                                <v-icon left>mdi-text-box</v-icon>Banco de Dados
                            </v-tab>

                            <v-tabs-items v-model="tab">
                                <v-tab-item key="tabTokens">
                                    <div>
                                        <div class="d-flex justify-space-between">
                                            <v-btn color="primary" class="text-none mt-1" size="small" @click="newToken"
                                                :disabled="!user.usuTerminalTokenIncluir">
                                                <v-icon left>mdi-plus</v-icon>Gerar Novo Token
                                            </v-btn>
                                            <div class="d-flex">
                                                <v-checkbox v-model="listarAtivos" class="mr-10" label="Ativos"
                                                    @click="statusClick"></v-checkbox>
                                                <v-checkbox v-model="listarInativos" label="Desativados"
                                                    @click="statusClick"></v-checkbox>
                                            </div>
                                        </div>
                                        <TokensComponent :listTokens="listTokens" :rtg_id="rtgrupos.rtg_id"
                                            @tokensChanged="tokensChanged" />
                                    </div>
                                </v-tab-item>
                                <v-tab-item key="tabEmpresas" v-if="isSeculos() == false">
                                    <EmpresasComponent :listEmpresas="listEmpresas" :rtg_id="rtgrupos.rtg_id"
                                        :cliente="cliente" @empresasChanged="empresasChanged" />
                                </v-tab-item>
                                <v-tab-item key="tabConexoesSeguras">
                                    <ConexoesComponent :listConexoes="listConexoes" :rtg_id="rtgrupos.rtg_id"
                                        @conexoesChanged="conexoesChanged" />
                                </v-tab-item>
                                <v-tab-item key="tabDatabase" v-if="isSeculos() == false">
                                    <DatabaseComponent :listDatabase="listDatabase" :rtg_id="rtgrupos.rtg_id"
                                        @databaseChanged="databaseChanged" />
                                </v-tab-item>
                            </v-tabs-items>
                        </v-tabs>
                    </v-col>
                </v-row>
                ID: {{ rtgrupos.rtg_id }}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>

import * as rttokensService from '../../services/rttokensService.js'
import * as rtconexoesService from '../../services/rtconexoesService.js'
import * as rtgruposService from '../../services/rtgruposService.js'
import * as riddbService from '../../services/riddbService.js'

import NewTokenDialog from './NewTokenDialog.vue';
import TokensComponent from './components/TokensComponent.vue';
import EmpresasComponent from './components/EmpresasComponent.vue';
import ConexoesComponent from './components/ConexoesComponent.vue';
import DatabaseComponent from './components/DatabaseComponent.vue';

import { mapGetters } from 'vuex'

export default {
    props: {
        dialog: Boolean,
        cliente: {}
    },
    components: {
        NewTokenDialog,
        TokensComponent,
        EmpresasComponent,
        ConexoesComponent,
        DatabaseComponent,
    },
    data: () => {
        return {
            tab: 0,
            rtg_qtmaquinas: 0,
            dialogNewToken: false,
            listarAtivos: true,
            listarInativos: false,
            listTokens: [],
            listEmpresas: [],
            listConexoes: [],
            listDatabase: [],
            rtgrupos: {
                rtg_id: "",
                rtg_qtmaquinas: 0
            },
            headers: [
                { text: 'CNPJ', value: 'cnpj' },
                { text: 'Nome', value: 'nome' },
                { text: 'Fantasia', value: 'nomeFantasia' },
                { text: 'UF', value: 'uf' },
                { text: 'Cidade', value: 'cidade' },
                { text: 'Filial', value: 'filial' },
                { text: 'Ativo?', value: 'ativo' }
            ]
        }
    },
    computed: {
        ...mapGetters(['user']),
        isDialog: {
            get() {
                return this.dialog
            },
            set() {
                this.dialogClose()
            },
        }
    }, methods: {
        async loadTokens() {
            this.$isLoading(true);
            try {
                await this.loadTokensImpl();
            } catch (error) {
                this.$store.dispatch('showError', error)
            } finally {
                this.$isLoading(false);
            }
            // eslint-disable-next-line no-console
            //console.log(this.listTokens);
        }, async loadTokensImpl() {
            try {
                this.listTokens = [];
                let params = { ativos: this.listarAtivos, inativos: this.listarInativos }
                this.listTokens = await rttokensService.getAll(this.rtgrupos.rtg_id, params);
            } catch (error) {
                this.$store.dispatch('showError', error)
            }
        }, async loadEmpresas() {
            this.$isLoading(true);
            this.listEmpresas = [];
            try {
                this.listEmpresas = await rtgruposService.getEmpresas(this.rtgrupos.rtg_id);
            } catch (error) {
                this.$store.dispatch('showError', error)
            } finally {
                this.$isLoading(false);
            }
        }, async loadConexoes() {
            this.$isLoading(true);
            this.listConexoes = [];
            try {
                this.listConexoes = await rtconexoesService.getAll(this.rtgrupos.rtg_id);
            } catch (error) {
                this.$store.dispatch('showError', error)
            } finally {
                this.$isLoading(false);
            }
        }, async loadDatabase() {
            this.$isLoading(true);
            this.listDatabase = [];
            try {
                this.listDatabase = await riddbService.getAll(this.rtgrupos.rtg_id);
            } catch (error) {
                this.$store.dispatch('showError', error)
            } finally {
                this.$isLoading(false);
            }
        }, async statusClick() {
            await this.loadTokens();
        }, newToken() {
            this.dialogNewToken = true;
        }, async dialogNewTokenClose() {
            this.dialogNewToken = false;
            await this.loadTokens();
        }, dialogClose() {
            this.$emit('dialogClose')
        }, async tokensChanged() {
            await this.loadTokens();
        }, async conexoesChanged() {
            await this.loadConexoes();
        }, async empresasChanged() {
            await this.loadEmpresas();
        }, async databaseChanged() {
            await this.loadDatabase();
        }, async rtg_qtmaquinasChanged() {
            this.$isLoading(true);

            try {
                await rtgruposService.setqtmaquinas(this.rtgrupos.rtg_id, this.rtgrupos.rtg_qtmaquinas);
                this.$store.dispatch('showSuccess', "Salvo com sucesso!")
            } catch (error) {
                this.$store.dispatch('showError', error)
            } finally {
                this.$isLoading(false);
            }
        }, isSeculos() {
            if (this.cliente.id == 1)
                return true;
            if (this.cliente.id == 44)
                return true;
            return false;
        }
    }, watch: {
        async dialog() {
            if (this.dialog) {
                this.listTokens = []
                this.listEmpresas = []
                this.listConexoes = []
                this.listDatabase = []
                this.tab = 0
                this.rtgrupos = {}

                // eslint-disable-next-line no-console
                //console.log(this.cliente.id);
                this.$isLoading(true);
                try {
                    this.rtgrupos = await rtgruposService.createAuto(this.cliente.id);
                    await this.loadTokensImpl();
                } catch (error) {
                    this.$store.dispatch('showError', error)
                } finally {
                    this.$isLoading(false);
                }
            }
        },
    },
}
</script>