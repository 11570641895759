import Vue from 'vue'
import './plugins/axios'
import './mixins'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import mask from './plugins/vue-the-mask'
import loading from './plugins/loading'

Vue.config.productionTip = false

new Vue({
	mask,
	loading,
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
