<template>
	<v-dialog v-model="isDialog" :max-width="900">
		<v-card>
			<v-card-title class="mb-2">
				Empresas
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-text-field v-model="search" append-icon="search"
							label="Pesquisar pelo Nome, Fantasia ou CNPJ" single-line hide-details></v-text-field>
					</v-col>
				</v-row>
				<v-data-table :headers="headers" :items="listClientes" :height="600" disable-pagination
					hide-default-footer dense class="elevation-1" :loading="loadingDatas">
					<template v-slot:[`item.actions`]="{ item }">
						<v-btn class="text-none" color="primary" text @click="incluir(item)">
							<v-icon left>add_circle</v-icon>Incluir
						</v-btn>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import * as clientesService from '../../services/clientesService.js'

export default {
	props: {
		rtg_id: String,
		dialog: Boolean
	}, data: function () {
		return {
			listClientes: [],
			search: '',
			headers: [
				{ text: 'CNPJ', value: 'cnpj' },
				{ text: 'Nome', value: 'nome' },
				{ text: 'Fantasia', value: 'nomeFantasia' },
				{ text: 'UF', value: 'uf' },
				{ text: 'Cidade', value: 'cidade' },
				{ text: 'Ação', value: 'actions' },
			],
			loadingDatas: false,
		}
	}, methods: {
		async loadClientes() {
			try {
				if (this.loadingDatas) return

				this.loadingDatas = true

				this.$isLoading(true);
				const response = await clientesService.clientes_nao_associados(this.search);
				this.listClientes = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.$isLoading(false);
				this.loadingDatas = false
			}
		}, loadClientesSearch() {
			this.loadClientes()
		}, dialogClose() {
			this.$emit('dialogClose')
		}, async incluir(item) {
			try {
				await clientesService.associar_grupo_terminal(item.id, this.rtg_id);

				this.$emit('conexoesChanged')
				this.$store.dispatch('showSuccess', 'Empresa incluída com sucesso!')
				this.dialogClose();
			} catch (error) {
				// eslint-disable-next-line no-console
				// console.log(error.response);
				this.$store.dispatch('showError', error)
			}
		}
	}, computed: {
		isDialog: {
			get() {
				return this.dialog
			}, set() {
				this.dialogClose();
			}
		}
	}, watch: {
		dialog() {
			this.loadClientes()
		}, search() {
			if (this.search.length > 3 || this.search.length == 0)
				this.loadClientesSearch()
		}
	}
}
</script>