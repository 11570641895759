<template>
	<v-dialog v-model="clienteDialog" max-width="850" scrollable>
		<v-card>
			<v-card-title class="mb-2">
				<v-icon left>mdi-clock-time-five</v-icon>
				Histórico de Alterações
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="8">
						<v-text-field
							hide-details=""
							solo
							dense
							placeholder="Pesquisar"
							v-model="search"
							append-icon="search"
						></v-text-field>
					</v-col>
					<v-col>
						<DataPicker
							v-model="datas"
							ref="calendario"
							@click="loadHistorico"
							@close="closeDataPicker()"
						/>
					</v-col>
				</v-row>
				<v-card>
					<v-data-table
						:headers="headers"
						:items="historico"
						:options.sync="options"
						:server-items-length="total"
						class="elevation-1"
						:loading="loading"
					>
					</v-data-table>
				</v-card>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DataPicker from '../shared/DataPicker.vue'

export default {
	props: {
		id: String,
		dialog: Boolean,
	},
	components: { DataPicker },
	data: function () {
		return {
			loading: false,
			datas: [],
			quantidade: 0,
			historico: [],
			total: 0,
			search: '',
			headers: [
				//{ text: 'ID', value: 'id' },
				{ text: 'Quem alterou?', value: 'quemAlterou' },
				{ text: 'Campo', value: 'campo' },
				{ text: 'Valor Anterior', value: 'valorAnt' },
				{ text: 'Valor Novo', value: 'valorNov' },
				{ text: 'Data da Alteração', value: 'dataAlteracao' },
			],
			options: {},
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.quantidade = 0
			this.$refs.calendario.date = ''
			this.datas = []
			this.search = ''
			this.historico = []
			this.$emit('dialogClose')
		},
		closeDataPicker() {
			this.datas = []
			this.loadHistorico()
		},
		async loadHistorico() {
			try {
				this.loading = true

				const { page, itemsPerPage } = this.options

				const response = await this.axios.get(`/clientes/${this.id}/historico`, {
					params: {
						page,
						limit: itemsPerPage,
						search: this.search,
						datas: this.datas,
					},
				})

				this.historico = response.data.historicoDeAlteracoes
				this.total = response.data.total
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		dialog() {
			if (this.dialog) this.loadHistorico()
		},
		search() {
			this.loadHistorico()
		},
		options: {
			handler() {
				this.$emit('setOptions', this.options)
				this.loadHistorico()
			},
			deep: true,
		},
	},
}
</script>
