<template>
	<v-dialog v-model="clienteDialog" :max-width="500" scrollable>
		<v-card>
			<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
			<v-card-title class="pb-0">
				<v-icon left>mdi-cellphone</v-icon>
				Aplicativos
				<v-spacer></v-spacer>
				<v-btn icon @click="dialogClose">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row justify="start">
					<v-col>
						<v-checkbox
							label="Cotação"
							v-model="usarCotacao"
							:disabled="!cliente.ativo"
							@change="setUsarCotacao()"
							hide-details
						></v-checkbox>
					</v-col>
					<v-col>
						<v-checkbox
							label="Notifica"
							v-model="usarNotifica"
							:disabled="!cliente.ativo"
							@change="setUsarNotifica()"
							hide-details
						></v-checkbox>
					</v-col>
					<v-col>
						<v-checkbox
							label="Catálogo"
							v-model="usarCatalogo"
							:disabled="!cliente.ativo"
							@change="setUsarCatalogo()"
							hide-details
						></v-checkbox>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="pt-0">
						<v-text-field v-model="cliente.chaveApps" label="Chave" readonly hide-details>
							<template v-slot:append>
								<v-icon color="primary" @click="copiarChave(cliente.chaveApps)">
									mdi-content-copy
								</v-icon>
							</template>
						</v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="pt-0">
						<v-text-field label="Chave Mobile" v-model="cliente.chaveAppsMobile" readonly hide-details>
							<template v-slot:append>
								<v-icon color="primary" @click="copiarChave(cliente.chaveAppsMobile)">
									mdi-content-copy
								</v-icon>
							</template>
						</v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn
							color="primary"
							:disabled="cliente.unificarChaves || !cliente.ativo"
							@click="unificarChaves()"
							:loading="loading"
							>Unificar chaves</v-btn
						>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
	props: {
		cliente: Object,
		dialog: Boolean,
	},
	data: function () {
		return {
			loading: false,
			usarCotacao: this.cliente.usarCotacao == 'S' ? true : false,
			usarNotifica: this.cliente.usarNotifica == 'S' ? true : false,
			usarCatalogo: this.cliente.usarCatalogo == 'S' ? true : false,
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
		...mapState(['user']),
		clienteDialog: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
		loadCliente() {
			this.$emit('loadCliente')
		},
		copiarChave(chave) {
			navigator.clipboard
				.writeText(chave)
				.then(() => {
					this.$store.dispatch('showSuccess', 'Chave copiada com sucesso!')
				})
				.catch(() => {
					this.$store.dispatch('showError', 'Erro ao copiar chave!')
				})
		},
		async setUsarCotacao() {
			try {
				this.loading = true
				await this.axios.put(`/clientes/${this.cliente.id}/apps/usarcotacao`, {
					usarCotacao: this.usarCotacao,
				})
				const msg = this.usarCotacao
					? 'O cliente agora pode usar a Cotação Web'
					: 'O cliente agora não pode usar a Cotação Web!'
				this.$store.dispatch('showSuccess', msg)
			} catch (error) {
				this.usarCotacao = !this.usarCotacao
				this.$store.dispatch('showError', 'Erro! Tente novamente mais tarde!')
			} finally {
				this.loading = false
			}
		},
		async setUsarNotifica() {
			try {
				this.loading = true
				await this.axios.put(`/clientes/${this.cliente.id}/apps/usarnotifica`, {
					usarNotifica: this.usarNotifica,
				})
				const msg = this.usarNotifica
					? 'O cliente agora pode usar o Notifica'
					: 'O cliente agora não pode usar o Notifica!'
				this.$store.dispatch('showSuccess', msg)
			} catch (error) {
				this.usarNotifica = !this.usarNotifica
				this.$store.dispatch('showError', 'Erro! Tente novamente mais tarde!')
			} finally {
				this.loading = false
			}
		},
		async setUsarCatalogo() {
			try {
				this.loading = true
				await this.axios.put(`/clientes/${this.cliente.id}/apps/usarcatalogo`, {
					usarCatalogo: this.usarCatalogo,
				})
				const msg = this.usarCatalogo
					? 'O cliente agora pode usar o Catálogo'
					: 'O cliente agora não pode usar o Catálogo!'
				this.$store.dispatch('showSuccess', msg)
			} catch (error) {
				this.usarCatalogo = !this.usarCatalogo
				this.$store.dispatch('showError', 'Erro! Tente novamente mais tarde!')
			} finally {
				this.loading = false
			}
		},
		async unificarChaves() {
			try {
				this.loading = true
				await this.axios.put(`/clientes/${this.cliente.id}/apps/unificar`)
				this.$store.dispatch('showSuccess', 'Chaves unificadas com sucesso!')
				this.loadCliente()
			} catch (error) {
				this.$store.dispatch('showError', 'Erro! Tente novamente mais tarde!')
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		cliente(){
			this.usarCotacao = this.cliente.usarCotacao == 'S' ? true : false
			this.usarNotifica = this.cliente.usarNotifica == 'S' ? true : false
			this.usarCatalogo = this.cliente.usarCatalogo == 'S' ? true : false
		}
	}
}
</script>