<template>
	<div>
		<ClienteDialog :dialog="dialog" :clienteId="clienteId" @dialogClose="dialogClose" />
		<FiltrarRelatorioDialog
			:dialog="dialogFiltrar"
			@dialogClose="dialogFiltrarClose"
			@salvarFiltros="salvarFiltros"
			:posicaoAtual="posicao"
			:conteudoAtual="conteudo"
			:clientesAtivosAtual="clientesAtivos"
			:clientesAtrasadosAtual="clientesAtrasados"
			:clienteSemContasAtual="clienteSemContas"
			:versoesDiferentesAtual="versoesDiferentes"
			:clientesSemContasMesAtual="clientesSemContasMes"
			:cidadeAtual="cidade"
			:semVersaoAtual="semVersao"
			:cidades="cidades"
			:camposConfiguracao = "camposConfiguracao"
		></FiltrarRelatorioDialog>
		<v-card>
			<v-card-title> <v-icon left color="primary">pageview</v-icon>Relatório de versões </v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="12" md="3">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Pesquisar pelo Nome, Nome Fantasia ou CNPJ do cliente"
							single-line
							hide-details
						/>
					</v-col>
					<v-col cols="12" sm="12" md="3">
						<v-text-field
							v-model="consultor"
							append-icon="consultor"
							label="Pesquisar pelo Consultor"
							single-line
							hide-details
						/>
					</v-col>
					<v-col cols="12" sm="12" md="2">
						<v-text-field v-model="versao" append-icon="search" label="Versão" single-line hide-details />
					</v-col>
					<v-col cols="12" sm="12" md="1">
						<v-select v-model="operadorFiltro" :items="operadoresFiltro" single-line hide-details />
					</v-col>
					<v-col cols="12" sm="12" md="2">
						<v-menu ref="menu1" transition="scale-transition" offset-y max-width="290px" min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									label="Último Acesso"
									v-model="ultimoAcessoFormatado"
									persistent-hint
									append-icon="mdi-calendar"
									clearable
									clear-icon="mdi-close"
									@click:clear="ultimoAcesso = ultimoAcessoFormatado = ''"
									v-bind="attrs"
									v-on="on"
									hide-details
								></v-text-field>
							</template>
							<v-date-picker v-model="ultimoAcesso" no-title></v-date-picker>
						</v-menu>
						<!-- <v-text-field
							v-model="ultimoAcesso"
							append-icon="search"
							label="Ult. Acesso"
							single-line
							hide-details
						/> -->
					</v-col>
					<v-col cols="12" sm="12" md="1">
						<v-select
							v-model="operadorFiltroUltAcesso"
							:items="operadoresFiltro"
							single-line
							hide-details
						/>
					</v-col>
				</v-row>
				<v-col>
					<v-row class="mb-2">
						<v-btn text color="primary" @click="dialogFiltrarOpen">
							<v-icon>mdi-filter</v-icon>
							Filtrar clientes
						</v-btn>

						<v-spacer />

						<downloadexcel
							class="v-btn v-btn--contained theme--light v-size--default primary"
							:data="JSON.parse(JSON.stringify(clientes))"
							:fields="fieldsExcel"
							worksheet="relatorio"
							name="relatorio-versoes.xls"
						>
							DOWNLOAD EXCEL
						</downloadexcel>
					</v-row>
				</v-col>
				<v-data-table
					:headers="headers"
					:items="clientes"
					:options.sync="options"
					:server-items-length="total"
					class="elevation-1"
					:loading="loading"
					dense
					@click:row="clienteSelected"
				>
					<template v-slot:[`item.ultAcesso`]="{ item }">
						{{ convertDate(item.ultAcesso) + ' ' + convertTime(item.ultAcesso) }}
					</template>
					<template v-slot:[`item.dataCombinada`]="{ item }">
						{{
							item.situacao == 'ATRASO' || item.semContas == 'true' ? convertDate(item.dataCombinada) : ''
						}}
					</template>
					<template v-slot:[`item.vencAntigo`]="{ item }">
						{{ convertDate(item.vencAntigo) }}
					</template>
					<template v-slot:[`item.matriz`]="{ item }">
						{{ item.matriz ? 'Sim' : 'Não' }}
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
// import VueCsvDownloader from 'vue-csv-downloader';
// Caso futuramente precise implementar download CSV, já está tudo pronto, só importar o pacote.
// componente: VueCsvDownloader
// codigo: <vue-csv-downloader class ="v-btn v-btn--contained theme--light v-size--default primary" :data="clientes" :fields="columnsCSV">DOWNLOAD CSV </vue-csv-downloader>
// fields: columnsCSV: ['nomeFantasia', 'nome', 'cnpj', 'cidade', 'uf', 'qtdComp', 'versaoMinima', 'versaoMaxima', 'ultAcesso', 'consultor', 'status', 'situacao'],

// https://www.npmjs.com/package/vue-csv-downloader

import downloadexcel from 'vue-json-excel'
import { mapGetters } from 'vuex'
import ClienteDialog from '@/components/clientes/ClienteDialog'
import FiltrarRelatorioDialog from '../components/relatorios/FiltrarRelatorioDialog.vue'

export default {
	components: {
		downloadexcel,
		ClienteDialog,
		FiltrarRelatorioDialog,
	},
	data: function () {
		return {
			clientes: [],
			headers: [
				{ text: 'N.Fantasia', value: 'nomeFantasia', sortable: false },
				{ text: 'N.Razão', value: 'nome', sortable: false },
				{ text: 'CNPJ', value: 'cnpj', sortable: false },
				{ text: 'Cidade', value: 'cidade', sortable: false },
				{ text: 'UF', value: 'uf', sortable: false },
				{ text: 'Qtd.Comp.', value: 'qtdComp', sortable: false },
				{ text: 'V.Min', value: 'versaoMinima', sortable: false },
				{ text: 'V.Max', value: 'versaoMaxima', sortable: false },
				{ text: 'Ult.Acesso', value: 'ultAcesso', sortable: false },
				{ text: 'Consultor', value: 'consultor', sortable: false },
				{ text: 'Status', value: 'status', sortable: false },
				{ text: 'Situação', value: 'situacao', sortable: false },
				{ text: 'Data Combinada', value: 'dataCombinada', sortable: false },
				{ text: 'Venc+Antigo', value: 'vencAntigo', sortable: false },
				{ text: 'É filial?', value: 'matriz', sortable: false },
				{ text: 'Px/Posição/Conteúdo', value: 'px', sortable: false },
				{ text: 'Configuração', value: 'configuracao'}
			],
			fieldsExcel: {
				NOMERAZAO: 'nome',
				NOMEFANTASIA: 'nomeFantasia',
				CNPJ: 'cnpj',
				CIDADE: 'cidade',
				UF: 'uf',
				QTDCOMPUTADORES: 'qtdComp',
				VERSAOMINIMA: 'versaoMinima',
				VERSAOMAXIMA: 'versaoMaxima',
				ULTACESSO: {
					field: 'ultAcesso',
					callback: (value) => {
						return this.convertDate(value) + ' ' + this.convertTime(value)
					},
				},
				CONSULTOR: 'consultor',
				STATUS: 'status',
				SITUACAO: 'situacao',
				DATACOMBINADA: {
					callback: (value) => {
						return value.situacao == 'ATRASO' || value.semContas == 'true'
							? this.convertDate(value.dataCombinada)
							: ''
					},
				},
				'VENC+ANTIGO': {
					field: 'vencAntigo',
					callback: (value) => {
						return this.convertDate(value)
					},
				},
				'É filial?': {
					field: 'matriz',
					callback: (value) => {
						return value ? 'Sim' : 'Não'
					},
				},
				'Px/Posição/Conteúdo': 'px',
			},
			operadorFiltro: '=',
			operadorFiltroUltAcesso: '=',
			operadoresFiltro: ['=', '>=', '>', '<', '<='],
			search: '',
			versao: '',
			ultimoAcesso: '',
			ultimoAcessoFormatado: '',
			consultor: '',
			loading: false,
			options: {},
			total: 0,
			dialog: false,
			computadorId: '',
			compSeculos: false,
			clientesAtivos: true,
			dataPicker: false,
			clientesAtrasados: false,
			clienteSemContas: false,
			clientesSemContasMes: false,
			semVersao: false,
			clienteId: '',
			tipoPx: '',
			posicao: '',
			conteudo: '',
			dialogFiltrar: false,
			versoesDiferentes: false,
			cidade: '',
			cidades: [],
			configuracao: '',
			camposConfiguracao: []
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
	},
	methods: {
		async loadClientes() {
			try {
				this.loading = true
				const { page, itemsPerPage } = this.options

				const response = await this.axios.get('/relatorios', {
					params: {
						page,
						limit: itemsPerPage,
						search: this.search,
						filtroVersao: this.operadorFiltro,
						versao: this.versao,
						filtroUltAcesso: this.operadorFiltroUltAcesso,
						ultimoAcesso: this.ultimoAcesso,
						consultor: this.consultor,
						clientesAtivos: this.clientesAtivos,
						clientesAtrasados: this.clientesAtrasados,
						clienteSemContas: this.clienteSemContas,
						clientesSemVersao: this.semVersao,
						posicao: this.posicao,
						tipoPx: this.tipoPx,
						conteudo: this.conteudo,
						versoesDiferentes: this.versoesDiferentes,
						cidade: this.cidade,
						configuracao: this.configuracao,
						clientesSemContasMes: this.clientesSemContasMes
					},
				})
				this.clientes = response.data.clientes
				this.total = response.data.total
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async loadCidades() {
			try {
				this.loading = true

				const response = await this.axios.get('/relatorios/cidades', {
				})
				this.cidades = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async loadCamposConfiguracao() {
			try {
				this.loading = true

				const response = await this.axios.get('/lojas/campos', {
				})
				this.camposConfiguracao = response.data
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async clienteSelected(cliente) {
			this.dialog = true
			this.clienteId = cliente.id
		},
		dialogClose() {
			this.clienteId = ''
			this.dialog = false
			this.loadClientes()
		},
		converterData(date) {
			const [year, month, day] = date.split('-')
			const separador = '/'

			return day + separador + month + separador + year
		},
		loadClientesSearch() {
			this.options.page = 1
			this.loadClientes()
		},
		// dialogClose() {
		// 	this.computadorId = ''
		// 	this.dialog = false
		// 	this.loadClientes()
		// },
		dialogFiltrarClose() {
			this.dialogFiltrar = false
		},
		dialogFiltrarOpen() {
			this.dialogFiltrar = true
		},
		salvarFiltros(
			tipoPx,
			posicao,
			conteudo,
			clientesAtivos,
			clientesAtrasados,
			clienteSemContas,
			semVersao,
			versoesDiferentes,
			cidade,
			configuracao,
			clientesSemContasMes
		) {
			this.tipoPx = tipoPx
			this.posicao = posicao
			this.conteudo = conteudo
			this.clientesAtivos = clientesAtivos
			this.clientesAtrasados = clientesAtrasados
			this.clienteSemContas = clienteSemContas
			this.semVersao = semVersao
			this.versoesDiferentes = versoesDiferentes
			this.cidade = cidade
			this.configuracao = configuracao
			this.clientesSemContasMes = clientesSemContasMes

			this.loadClientes()
		},
	},
	mounted() {
		this.loadClientes()
		this.loadCidades()
		this.loadCamposConfiguracao()
	},
	watch: {
		options: {
			handler() {
				this.loadClientes()
			},
			deep: true,
		},
		search() {
			this.loadClientesSearch()
		},
		versao() {
			if (this.versao.length > 3 || this.versao.length == 0) this.loadClientesSearch()
		},
		operadorFiltro() {
			this.loadClientesSearch()
		},
		consultor() {
			this.loadClientesSearch()
		},
		ultimoAcesso() {
			this.ultimoAcessoFormatado = this.converterData(this.ultimoAcesso)
			this.loadClientesSearch()
		},
		operadorFiltroUltAcesso() {
			this.loadClientesSearch()
		},
	},
}
</script>
