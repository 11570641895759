import { ApiService } from './common/serviceBase';
import { URL_CAD_REGISTRO_TERMINAL } from './common/apiUrl';

const service = new ApiService(`${URL_CAD_REGISTRO_TERMINAL}/rtconexoes/`);

export async function getAll(idDb) {
    const response = await service.get(idDb);
    return response.data;
}

export async function create(data) {
    const response = await service.post('', data);
    return response;
}

export async function remove(rtc_id) {
    const response = await service.delete(rtc_id);
    return response;
}
