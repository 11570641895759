<template>
	<v-data-table :headers="headers" :items="contas" disable-pagination hide-default-footer>
		<template v-slot:[`item.dataVen`]="{ item }">{{ convertDate(item.dataVen) }}</template>
		<template v-slot:[`item.dataPagamento`]="{ item }">{{ !item.dataPag ? convertDate(item.dataUltPag) : convertDate(item.dataPag)  }}</template>
		<template v-slot:[`item.valor`]="{ item }">R${{ item.valor.toString().replace('.', ',') }}</template>
		<template v-slot:[`item.valorReceber`]="{ item }">R${{ item.valorReceber.toString().replace('.', ',') }}</template>
		<template v-slot:[`item.valorAtualizado`]="{ item }">{{
			mostrarValorAtualizado(item) ? 'R$' + item.valorAtualizado.toString().replace('.', ',') : ''
		}}</template>
		<template v-slot:[`item.icone`]="{ item }">
			<v-icon :title="titulo(item)" :color="cor(item)">{{ icone(item) }}</v-icon>
		</template>
		<template v-slot:[`item.pagaComPix`]="{ item }"> {{ item.pagaComPix ? 'Sim' : 'Não' }}</template>
	</v-data-table>
</template>
<script>
export default {
	props: ['contas'],
	data: () => ({
		headers: [
			{ text: 'Sequência', value: 'crmSeqinterna' },
			{ text: 'Vencimento', value: 'dataVen' },
			{ text: 'Pagamento', value: 'dataPagamento' },
			{ text: 'Valor Original', value: 'valor' },
			{ text: 'Vl a Receber', value: 'valorReceber' },
			{ text: 'Valor Atualizado', value: 'valorAtualizado' },
			{ text: 'Status', value: 'icone', shortable: false },
			{ text: 'Paga com PIX?', value: 'pagaComPix', shortable: false },
			{ text: 'ID Integração', value: 'boletoId', shortable: false },
		],
	}),
	methods: {
		icone(conta) {
			if (conta.cancelada) return 'remove_circle_outline'
			if (conta.dataPag || conta.pagaComPix) return 'check'
			return 'access_time'
		},
		cor(conta) {
			if (conta.cancelada) return 'grey'
			if (conta.dataPag || conta.pagaComPix) return 'teal'

			let dateNow = Math.floor(new Date().getTime() / 1000)
			let dataVen = Math.floor(new Date(conta.dataVen).getTime() / 1000)
			if (!conta.dataPag && dateNow - dataVen > 0) {
				return dateNow - dataVen > 60 * 60 * 24 * 30 ? 'red' : 'warning'
			}
		},
		titulo(conta) {
			if (conta.cancelada) return 'Cancelada.'
			if (conta.dataPag) return 'Paga.'

			let dateNow = Math.floor(new Date().getTime() / 1000)
			let dataVen = Math.floor(new Date(conta.dataVen).getTime() / 1000)
			if (!conta.dataPag && dateNow - dataVen > 0) {
				return dateNow - dataVen > 60 * 60 * 24 * 30 ? 'Muito Atrasada!!!' : 'Atrasada!'
			}
		},
		mostrarValorAtualizado(conta) {
			let dateNow = Math.floor(new Date().getTime() / 1000)
			let dataVen = Math.floor(new Date(conta.dataVen).getTime() / 1000)
			return conta.valorAtualizado && !conta.dataPag && dateNow - dataVen > 0
		},
	},
}
</script>