<template>
	<div>
		<v-card>
			<v-card-title>
				<IconBase width="25" height="25" iconColor="#1976d2" class="pt-2 mr-2"><PixIcon /></IconBase>Consulta PIX
			</v-card-title>
			<v-card-text>
				<DataPicker v-model="datas" @click="loadPix" @close="closeDataPicker" ref="calendario" />
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-text-field
							solo
							v-model="search"
							append-icon="search"
							label="Pesquisar pelo nome ou CNPJ"
							single-line
							hide-details
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-spacer />
					<downloadexcel
						class="v-btn v-btn--contained theme--light v-size--default primary mr-3 mb-3"
						:data="JSON.parse(JSON.stringify(clientes))"
						:fields="fieldsExcel"
						worksheet="relatorio"
						name="relatorio-pix.xls"
					>
						DOWNLOAD EXCEL
					</downloadexcel>
				</v-row>
				<v-data-table
					:search="search"
					:headers="headers"
					:items="clientes"
					class="elevation-1"
					:loading="loading"
				>
					<template v-slot:[`item.filial`]="{ item }">
						<v-icon color="success" v-if="isFilial(item.matriz)">check_circle_outline</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import PixIcon from '../components/icons/IconPix.vue'
import IconBase from '../components/shared/IconBase.vue'
import DataPicker from '../components/shared/DataPicker.vue'
import downloadexcel from 'vue-json-excel'

export default {
	components: { PixIcon, IconBase, DataPicker, downloadexcel },
	data: function () {
		return {
			clientes: [],
			datas: [],
			search: '',
			headers: [
				{ text: 'Nome', value: 'name' },
				{ text: 'CNPJ', value: 'cpfCnpj' },
				{ text: 'Quantidade', value: 'qtdPix', align: 'end' },
				{ text: 'Status', value: 'status' },
			],
			fieldsExcel: {
				Nome: 'name',
				CNPJ: 'cpfCnpj',
				Quantidade: 'qtdPix',
				Status: 'status',
			},
			loading: false,
			total: 0,
		}
	},
	methods: {
		closeDataPicker() {
			this.datas = []
		},
		async loadPix() {
			try {
				this.loading = true
				const response = await this.axios.get('/pix', {
					params: {
						datas: this.datas,
					},
				})

				this.clientes = response.data.clientes
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>
