<template>
	<v-dialog max-width="420" v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                Seta Data Combinada
            </v-btn>
        </template>

		<v-card>
			<v-card-title>
				<v-icon left>date_range</v-icon>Data Combinada				
				<v-spacer></v-spacer>
				<v-btn icon @click="dialog = false">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
            <v-card-subtitle>
                Alterar data combinada para todos os clientes com data combinada menor que hoje ou nula.
            </v-card-subtitle>
			<v-card-text>
                <v-menu
                    v-model="menuDataCombinada"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="dataCombinada"
                            label="Data combinada"
                            persistent-hint
                            prepend-icon="event"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" @input="menuDataCombinada = false"></v-date-picker>
                </v-menu>
            </v-card-text>
            <v-card-actions>
                <v-btn  color="blue darken-1" text @click="dialog = false" v-if="!loading">
                    Close
                </v-btn>
				<v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
                <v-spacer v-if="!loading"></v-spacer>
                <v-btn color="primary" v-if="!loading" @click="saveDataCombinada">Salvar</v-btn>
			</v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data() {
		return {		
            loading: false,
            date: null,
            menuDataCombinada: null,
            dialog: false,
		}
	},
    computed: {		
        dataCombinada() {
			return this.convertDate(this.date)
		},	
    },
    methods: {		
        async saveDataCombinada(){
            try {
				this.loading = true
				await this.axios.put(`config/dataCombinadaAll`, { dataCombinada: this.date })
                this.$store.dispatch('showSuccess', 'Data Combinada alterada com sucesso!')
                this.dialog = false
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
        }
    }
}
</script>