<template>
	<div>
		<ClienteDialog :dialog="dialog" :clienteId="clienteId" @dialogClose="dialogClose" />
		<v-card>
			<v-card-title> <v-icon color="primary" left>mdi-cash-multiple</v-icon>Boletos </v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Pesquisar pelo Nome ou CNPJ"
							single-line
							hide-details
						></v-text-field>
					</v-col>
				</v-row>
				<v-data-table
					:headers="headers"
					:items="boletos"
					:options.sync="options"
					:server-items-length="total"
					class="elevation-1"
					:loading="loading"
				>
					<template v-slot:[`item.valor`]="{ item }"
						>R${{ item.valor.toString().replace('.', ',') }}</template
					>
					<template v-slot:[`item.valorReceber`]="{ item }"
						>R${{ item.valorReceber.toString().replace('.', ',') }}</template
					>
					<template v-slot:[`item.valorAtualizado`]="{ item }">{{
						mostrarValorAtualizado(item) ? 'R$' + item.valorAtualizado.toString().replace('.', ',') : ''
					}}</template>
					<template v-slot:[`item.dataVen`]="{ item }">{{ convertDate(item.dataVen) }}</template>
					<template v-slot:[`item.naoEnviar`]="{ item }">
						<v-checkbox
							v-model="item.naoEnviar"
							@click="atualizarNaoEnviar(item)"
							v-if="!item.loading"
						></v-checkbox>
						<v-progress-circular
							indeterminate
							v-if="item.loading"
							:color="item.naoEnviar ? 'primary' : 'grey'"
							:size="26"
							class="mt-5 mb-5"
						></v-progress-circular>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
export default {
	data: function () {
		return {
			boletos: [],
			search: '',
			headers: [
				{ text: 'Nome', value: 'cliente.nome' },
				{ text: 'Fantasia', value: 'cliente.nomeFantasia' },
				{ text: 'CNPJ', value: 'cliente.cnpj' },
				{ text: 'Vencimento', value: 'dataVen' },
				{ text: 'Valor Original', value: 'valor' },
				{ text: 'Vl a Receber', value: 'valorReceber' },
				{ text: 'Valor Atualizado', value: 'valorAtualizado' },
				{ text: 'CRM Sequência', value: 'crmSeqinterna' },
				{ text: 'Não enviar?', value: 'naoEnviar' },
			],
			options: {},
			loading: false,
			total: 0,
		}
	},
	methods: {
		async loadBoletos() {
			try {
				if (this.loading) return

				this.loading = true
				const { sortBy, sortDesc, page, itemsPerPage } = this.options

				const response = await this.axios.get('/boletosadmin', {
					params: {
						page,
						limit: itemsPerPage,
						sortBy: sortBy[0],
						sortDesc: sortDesc[0],
						search: this.search,
					},
				})

				this.boletos = response.data.boletos
				this.total = response.data.total
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async atualizarNaoEnviar(item) {
			try {
				item.loading = true
				const response = await this.axios.put(`/boletos/${item.id}`, { naoEnviar: item.naoEnviar })

				if (response.status == 204) item.loading = false
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		},
		loadBoletosSearch() {
			this.options.page = 1
			this.loadBoletos()
		},
		async clienteSelected(cliente) {
			this.dialog = true
			this.clienteId = cliente.id
		},
		isFilial(matriz) {
			return matriz && matriz !== ''
		},
		dialogClose() {
			this.clienteId = ''
			this.dialog = false
			this.loadBoletos()
		},
		mostrarValorAtualizado(conta) {
			let dateNow = Math.floor(new Date().getTime() / 1000)
			let dataVen = Math.floor(new Date(conta.dataVen).getTime() / 1000)
			return conta.valorAtualizado && !conta.dataPag && dateNow - dataVen > 0
		},
	},
	watch: {
		options: {
			handler() {
				this.loadBoletos()
			},
			deep: true,
		},
		search() {
			this.loadBoletosSearch()
		},
	},
}
</script>
