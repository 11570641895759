<template>
	<div>
		<v-data-table :headers="headers" :items="listTokens" disable-pagination hide-default-footer height="400"
			class="teste">
			<template v-slot:[`item.rtt_token`]="{ item }">
				<div class="d-flex">
					<v-btn @click="copiaChave(item.rtt_token)" icon color="primary" title="Copiar Token!">
						<v-icon>file_copy</v-icon>
					</v-btn>
					<div class="d-flex align-center">
						{{ item.rtt_token }}
					</div>
				</div>
			</template>
			<template v-slot:[`item.rtt_inativo`]="{ item }">
				<v-btn v-if="item.rtt_inativo == false" class="text-none" size="small" text color="primary"
					@click="desativarToken(item)" :disabled="!user.usuTerminalTokenExcluir">
					<v-icon left>delete</v-icon>Desativar
				</v-btn>
				<span v-else>
					<v-icon color="error">mdi-close-circle-outline</v-icon> Desativado
				</span>
			</template>
			<template v-slot:[`item.rtt_ultimo_acesso`]="{ item }">
				<span v-if="item.rtt_ultimo_acesso">
					{{ convertDate(item.rtt_ultimo_acesso) + " " + convertTime(item.rtt_ultimo_acesso) + " ; " +
						calcularDiferenca(item.rtt_ultimo_acesso) }}
				</span>
			</template>
			<template v-slot:[`item.createAt`]="{ item }">
				<span v-if="item.createAt">
					{{ convertDate(item.createAt) + " " + convertTime(item.createAt) }}
				</span>
			</template>
			<template v-slot:[`item.rtt_ultimo_refresh_token`]="{ item }">
				<span v-if="item.rtt_ultimo_refresh_token">
					{{ convertDate(item.rtt_ultimo_refresh_token) + " " + convertTime(item.rtt_ultimo_refresh_token)
						+ " ; " + calcularDiferenca(item.rtt_ultimo_refresh_token) }}
				</span>
			</template>
			<template v-slot:[`item.em_uso`]="{ item }">
				<v-tooltip bottom v-if="diasDiff(item.rtt_ultimo_refresh_token) >= 10">
					<template v-slot:activator="{ on }">
						<v-icon color="grey" right v-on="on">access_time</v-icon>
					</template>
					<span>Há {{ diasDiff(item.rtt_ultimo_refresh_token) }} dias que o Token não faz refresh no servidor.
						Não está sendo
						usado!</span>
				</v-tooltip>
				<div v-else>
					<v-tooltip bottom v-if="item.rtt_ultimo_acesso">
						<template v-slot:activator="{ on }">
							<v-icon color="success" right v-on="on">check_circle_outline</v-icon>
						</template>
						<span>Token já está sendo usado</span>
					</v-tooltip>
					<v-tooltip bottom v-if="!item.rtt_ultimo_acesso">
						<template v-slot:activator="{ on }">
							<v-icon color="warning" right v-on="on">block</v-icon>
						</template>
						<span>Token disponível, não foi usado até o momento!</span>
					</v-tooltip>
				</div>

			</template>

		</v-data-table>
	</div>
</template>
<style scoped>
.teste {
	white-space: nowrap
}
</style>

<script>
import * as rttokensService from '../../../services/rttokensService.js'
import { mapGetters } from 'vuex'

export default {
	props: {
		listTokens: Array
	}, data: () => ({
		headers: [
			{ text: 'Token', value: 'rtt_token' },
			{ text: 'Status', value: 'rtt_inativo', align: 'center' },
			{ text: 'Em Uso', value: 'em_uso' },
			{ text: 'Terminal', value: 'rtt_terminal' },
			{ text: 'Versão', value: 'rtt_sic_versao' },
			{ text: 'Últ. Acesso', value: 'rtt_ultimo_acesso' },
			{ text: 'Últ. Refresh', value: 'rtt_ultimo_refresh_token' },
			{ text: 'Criado em:', value: 'createAt' },
		],
		dialogNewToken: false,
		listarAtivos: true,
		listarInativos: false,
	}), methods: {
		copiaChave(rtt_token) {
			navigator.clipboard.writeText(rtt_token)
			this.$store.dispatch('showSuccess', 'Token copiado com sucesso!')
		}, async desativarToken(item) {
			this.$isLoading(true);

			try {
				await rttokensService.desativar(item.rtt_token);
				this.$emit('tokensChanged')
				this.$store.dispatch('showSuccess', 'Token desativado com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.$isLoading(false);
			}
		}, calcularDiferenca(ultimo_acesso) {
			const days = this.diasDiff(ultimo_acesso);
			if (days > 0)
				return `(${days} dias)`;

			const agora = new Date();
			const ultimoAcesso = new Date(ultimo_acesso);

			// calcular a diferença em milissegundos
			const diferencaMs = agora - ultimoAcesso;
			// calcular a diferença em minutos
			const diferencaMinutos = Math.abs(Math.floor(diferencaMs / 1000 / 60));

			if (diferencaMinutos >= 60) {
				return `(${Math.floor(diferencaMinutos / 60)} horas)`;
			} else {
				return `(${diferencaMinutos} minutos)`;
			}
		}, diasDiff(date) {
			if (!date)
				return 0;

			const agora = new Date();
			const ultimoAcesso = new Date(date);

			// calcular a diferença em milissegundos
			const diferencaMs = agora - ultimoAcesso;
			// calcular a diferença em minutos
			const diferencaDias = Math.abs(Math.floor(diferencaMs / 1000 / 60 / 60 / 24));
			return diferencaDias;
		}
	}, computed: {
		...mapGetters(['user']),
	}
}
</script>