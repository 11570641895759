<template>
	<div>
		<v-data-table :headers="headers" :items="computadores" disable-pagination hide-default-footer>
			<template v-slot:[`item.createdAt`]="{ item }">{{ convertDate(item.createdAt)  + " " + convertTime(item.createdAt)}}</template>
			<template v-slot:[`item.ultimoAcesso`]="{ item }">{{ convertDate(item.ultimoAcesso)  + " " + convertTime(item.ultimoAcesso) }}</template>
			<template v-slot:[`item.acoes`]="{ item }">
				<span>
					<v-btn v-if="item.ativo === false" icon title="Ativar" @click="ativarComputador(item.id)" :disabled="!userAdmin">
						<v-icon color="success">check_circle</v-icon>
					</v-btn>
					<v-btn v-else icon title="Desativar" @click="desativarComputador(item.id)" :disabled="!userAdmin">
						<v-icon color="warning">block</v-icon>
					</v-btn>
					<v-btn title="Remover" icon @click="removerComputador(item.id)" :disabled="!userAdmin">
						<v-icon color="red">delete</v-icon>
					</v-btn>
				</span>
			</template>
		</v-data-table>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	props: ['computadores'],
	data: () => ({
		headers: [
			{ text: 'Nome', value: 'nome' },
			{ text: 'Versão', value: 'versao' },
			{ text: 'OS', value: 'sisOp' },
			{ text: 'Data Cadastro', value: 'createdAt' },
			{ text: 'Último Acesso', value: 'ultimoAcesso' },
			{ text: 'Ações', value: 'acoes', align: 'right' }
		]
	}),
	computed: {
		...mapGetters(['userAdmin']),
	},
	methods: {
		async removerComputador(id) {
			try {
				await this.axios.delete(`/computadores/${id}`)
				this.$emit('computadorChanged')
				this.$store.dispatch('showSuccess', 'Computador Removido com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		},
		async ativarComputador(id) {
			try {
				await this.axios.put(`/computadores/${id}/ativo`, { ativo: true })
				this.$emit('computadorChanged')
				this.$store.dispatch('showSuccess', 'Computador Ativado com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		},
		async desativarComputador(id) {
			try {
				await this.axios.put(`/computadores/${id}/ativo`, { ativo: false })
				this.$emit('computadorChanged')
				this.$store.dispatch('showSuccess', 'Computador Desativado com sucesso!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		}
	}
}
</script>