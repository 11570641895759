<template>
	<div>
		<v-card>
			<v-card-title> <v-icon color="primary" left>mdi-text-box</v-icon>Nota Segura </v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-text-field
							solo
							v-model="search"
							append-icon="search"
							label="Pesquisar pelo nome ou CNPJ"
							single-line
							hide-details
						></v-text-field>
					</v-col>
				</v-row>
				<v-row v-if="userAdmin" class="ml-1">
					<v-checkbox
						class="mt-1 mb-2"
						label="Desativado no SIC e ativo na Nota Segura"
						v-model="desativado"
						hide-details
						:disabled="loading || !userAdmin"
					></v-checkbox>
					<v-spacer />
					<downloadexcel
						class="v-btn v-btn--contained theme--light v-size--default primary mr-3 mb-3"
						:data="JSON.parse(JSON.stringify(returnExcel()))"
						:fields="fieldsExcel"
						worksheet="relatorio"
						name="relatorio-nota-segura.xls"
					>
						DOWNLOAD EXCEL
					</downloadexcel>
				</v-row>
				<v-data-table
					:headers="headers"
					:items="clientes"
					class="elevation-1"
					:loading="loading"
					:search="search"
				>
					<template v-slot:[`item.filial`]="{ item }">
						<v-icon color="success" v-if="isFilial(item.matriz)">check_circle_outline</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import downloadexcel from 'vue-json-excel'

export default {
	components: {
		downloadexcel,
	},
	data: function () {
		return {
			clientes: [],
			contentExcel: [],
			search: '',
			headers: [
				{ text: 'Razão Social', value: 'Corporate name' },
				{ text: 'Nome Fantasia', value: 'Name' },
				{ text: 'Cidade', value: 'cidade' },
				{ text: 'CNPJ/CPF', value: 'CNPJ' },
				{
					text: 'Nota Segura',
					value: 'Status',
					filter: (value) => {
						if (!this.desativado) return true
						return value == 'Ativo'
					},
				},
				{
					text: 'SIC',
					value: 'statusSic',
					filter: (value) => {
						if (!this.desativado) return true
						return value == 'Bloqueado' || value == 'Desativado'
					},
				},
			],
			fieldsExcel: {
				NOMERAZAO: 'Corporate name',
				NOMEFANTASIA: 'Name',
				CIDADE: 'cidade',
				CNPJ: 'CNPJ',
				'NOTA SEGURA': 'Status',
				SIC: 'statusSic',
			},
			loading: false,
			total: 0,
			desativado: false,
		}
	},
	computed: {
		...mapGetters(['userAdmin', 'userMaster']),
	},
	methods: {
		async loadClientes() {
			try {
				this.loading = true
				const response = await this.axios.get('/notaSegura')

				this.clientes = response.data.clientes
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		returnExcel(){
			if(this.desativado) return this.clientes.filter((cliente)=>{
				if((cliente.statusSic =='Bloqueado' || cliente.statusSic == 'Desativado') && cliente.Status == 'Ativo' ) return cliente
			})
			else return this.clientes
		}
	},
	mounted() {
		this.loadClientes()
	},
}
</script>